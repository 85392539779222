import classNames from "classnames";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AvendiLogo from "../../../assets/images/logo.svg";
import { DynamicRoutes } from "../../../constants/routes";
import { logoutUser } from "../../../utils/sessionManager";

import { appService } from "../../../services/appService";
import { listModule } from "../../../services/manageModuleService";
import { useEffect } from "react";
import LanguageSelect from "../../language/LanguageSelect";
import { useTranslation } from "react-i18next";
import { getValue } from "../../../utils/object";

export default function Index() {
  const { t } = useTranslation();
  const translation = t("sideMenu");

  const tenantData = appService.getTenantData();
  const { clientSettings } = tenantData;
  const Logo = tenantData.guestAppLogoUrl || tenantData.imageUrl || AvendiLogo;
  const [sideMenu, setSideMenu] = useState(false);
  const [module, setModule] = useState([]);
  const isLogoLargeAndCentered = clientSettings?.logoLargeAndCentered;
  const logoClasses = `dynamic_logo ${
    isLogoLargeAndCentered && "dynamic_logo--large"
  }`;

  const location = useLocation();

  const handleLogoutClick = () => {
    logoutUser();
    window.location.replace(DynamicRoutes.Login);
  };

  const handleMenuClick = () => {
    setSideMenu(!sideMenu);
  };
  useEffect(() => {
    listModule(setModule);
  }, []);

  const getModuleStatus = (modules, moduleName) =>
    modules
      ?.filter((item) => item.modulesName === moduleName)
      .map((item) => item.isActive);

  const feedback = getModuleStatus(module, "feedback");
  const hotelService = getModuleStatus(module, "hotelService");
  const inRoomDiningService = getModuleStatus(module, "inRoomDiningService");
  const experiences = getModuleStatus(module, "experiences");
  const museum = getModuleStatus(module, "museum");
  const directives = getModuleStatus(module, "directives");

  return (
    <>
      <header
        className="header_area"
        style={{ paddingTop: "10px", paddingBottom: "10px" }}
      >
        <div className="header-row">
          <nav
            className="menu"
            style={{ display: sideMenu ? "block" : "none", zIndex: 1000 }}
          >
            <ul onClick={handleMenuClick}>
              {directives[0] && (
                <li>
                  <Link
                    className={
                      [DynamicRoutes.Profile].includes(location.pathname)
                        ? "text-white bg-dark"
                        : ""
                    }
                    to={DynamicRoutes.Profile}
                  >
                    {getValue(translation, "hotelProfile")}
                  </Link>
                </li>
              )}

              {hotelService[0] && (
                <li>
                  <Link
                    className={
                      [DynamicRoutes.Services].includes(location.pathname)
                        ? "text-white bg-dark"
                        : ""
                    }
                    to={DynamicRoutes.Services}
                  >
                    {getValue(translation, "hotelServices")}
                  </Link>
                </li>
              )}
              {inRoomDiningService[0] && (
                <li>
                  <Link
                    className={
                      [DynamicRoutes.FoodMenu].includes(location.pathname)
                        ? "text-white bg-dark"
                        : ""
                    }
                    to={DynamicRoutes.FoodMenu}
                  >
                    {getValue(translation, "inRoomDining")}
                  </Link>
                </li>
              )}
              {experiences[0] && (
                <li>
                  <Link
                    className={
                      [DynamicRoutes.InternalServices].includes(
                        location.pathname
                      )
                        ? "text-white bg-dark"
                        : ""
                    }
                    to={DynamicRoutes.InternalServices}
                  >
                    {getValue(translation, "internalServices")}
                  </Link>
                </li>
              )}
              {clientSettings.enableFaq && !!clientSettings.faqAgentId && (
                <li>
                  <Link to={DynamicRoutes.Faq}>FAQ's</Link>
                </li>
              )}
              {museum[0] && (
                <li>
                  <Link
                    className={
                      [DynamicRoutes.Museum].includes(location.pathname)
                        ? "text-white bg-dark"
                        : ""
                    }
                    to={DynamicRoutes.Museum}
                  >
                    Museum
                  </Link>
                </li>
              )}
              {feedback[0] && (
                <li>
                  <Link
                    className={
                      [DynamicRoutes.Feedback].includes(location.pathname)
                        ? "text-white bg-dark"
                        : ""
                    }
                    to={DynamicRoutes.Feedback}
                  >
                    {getValue(translation, "feedback")}
                  </Link>
                </li>
              )}
              <li>
                <Link to="#logout" onClick={handleLogoutClick}>
                  {getValue(translation, "logOut")}
                </Link>
              </li>
            </ul>
            <div className="menu_bottom">
              <h4>powered by</h4>
              <Link to={DynamicRoutes.ClientRoot}>
                <img src={AvendiLogo} alt="avendi logo" />
              </Link>
            </div>
          </nav>
          <div
            className={classNames([
              {
                "logo--centered": isLogoLargeAndCentered,
                logo: !isLogoLargeAndCentered,
                logo_add_class: sideMenu,
                "d-flex": isLogoLargeAndCentered && !sideMenu,
              },
            ])}
          >
            <button
              type="button"
              onClick={handleMenuClick}
              className={`pl-2 cs_nav_toggle ${
                sideMenu ? "cs_cross_active" : ""
              }`}
              id="avendi_bar"
            >
              <span
                className={classNames([
                  "cs_ico",
                  { left_aligned: !isLogoLargeAndCentered },
                ])}
              >
                <span className="cs_mask" />
              </span>
            </button>

            <Link to={DynamicRoutes.ClientRoot}>
              <img src={Logo} alt="logo" className={logoClasses} />
            </Link>
            <div />
          </div>
          {!!clientSettings.guestAppHeaderRightLogo && !sideMenu && (
            <div>
              <img
                src={clientSettings.guestAppHeaderRightLogo}
                alt="right_logo"
                className={logoClasses}
              />
            </div>
          )}

          {sideMenu ? <></> : <LanguageSelect />}
        </div>
      </header>
    </>
  );
}
