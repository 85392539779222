import axios from 'axios';

import moment from 'moment';
import API from '../constants/api';
import { appService } from './appService';
import { getAccessToken, getUser } from '../utils/sessionManager';
import { GUEST_REQ_STATUS, INTERNAL_SERVICE_PRICE_TYPE } from '../constants';
// import printReceipt from '../utils/printReceipt';
// import { PinterToken } from '../constants/enum';

export const API_TIME_FORMAT = 'HH:mm';
const TimeUnitMap = Object.freeze({
  min: 'minutes',
});
export const ISPriceTypeLabels = Object.freeze({
  [INTERNAL_SERVICE_PRICE_TYPE.Fixed]: 'Fixed',
  [INTERNAL_SERVICE_PRICE_TYPE.PerPerson]: 'per Person',
});

const _divideTimeSlotsToInterval = (timeSlots, interval) => {
  const slots = (timeSlots || []).reduce((acc, curr) => {
    const [from, to] = curr.split('-');
    const duration = +interval.replace('min', '');
    const unit = TimeUnitMap.min;
  
    let head = from;
    while (
      moment(head, API_TIME_FORMAT).isBefore(moment(to, API_TIME_FORMAT))
    ) {
      const start = head;
      const end = moment(head, API_TIME_FORMAT)
        .add(duration, unit)
        .format(API_TIME_FORMAT);
  
      acc.push(`${start}-${end}`);
      head = end;
    }
    return acc;
  }, []);

return slots;
}

const isTimePassed = (time) => {
  const currentTime = moment();
  const inputTime = moment(time, API_TIME_FORMAT);

  // Set the input time to today
  inputTime.set({
    year: currentTime.year(),
    month: currentTime.month(),
    date: currentTime.date(),
  });

  return inputTime.isBefore(currentTime);
};

const getOnlyValidTimeSlotInterval = (selectedDate, slots=[])=>{
  const selectedMoment = moment(selectedDate).startOf('day');
  const currentMoment = moment();

  if (currentMoment.startOf('day').isBefore(selectedMoment)) {
    return slots;
  }

  const validSlots = slots.map((slot)=>{
    const slotTime = slot.split("-")[0];
    const isPassed = isTimePassed(slotTime)
    if (!isPassed) return slot; 
  }).filter((data) => !!data)

  return validSlots;
}

/**
 * Map new response of Internal service to the old view model.
 * @param {object} newISItem
 */
const _mapNewResponseToOldVm = (newISItem) => ({
  ...newISItem,
  isSetsAvailable: !!(newISItem.duration || []).length,
  price: newISItem.priceInfo[0].price,
  priceType: newISItem.priceInfo[0].priceType,
  _priceTypeLabel: ISPriceTypeLabels[newISItem.priceInfo[0].priceType],
  name: newISItem.title,
  duration: newISItem.markedAsHidden
    ? newISItem.duration
    : (newISItem.duration || []).map((d) => ({
      ...d,
      sets: (d.sets || []).map((set) => ({
        ...set,
        _availableTime: _divideTimeSlotsToInterval(set.availableTime, d.time),
      })),
    })),
});

/**
 * Get only the internal service categories (returns string)
 * that have at least one item in them.
 */
const getItemTypes = async (params = {}) => {
  const res = await axios.get(API.INTERNAL_SERVICE_ITEM_TYPES, {
    params,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
      username: appService.getTenantId(),
    },
  });

  return { data: res.data };
};

/**
 * Get all internal service item categories
 * @returns {object[]}
 */
const getCategories = async (params = {}, ignoreWithNoItems = true) => {
  try {
    const res = await axios.get(API.INTERNAL_SERVICE_CATEGORIES, {
      params,
      headers: {
        username: appService.getTenantId(),
        authorization: `Bearer ${getAccessToken()}`,
      },
    });

    if (ignoreWithNoItems) {
      const { data: categoriesWithItems = [] } = await getItemTypes();

      res.data.data = (res.data.data || []).filter((c) => categoriesWithItems.includes(c.internalServiceCategoryName));
    }

    // return { data: withMeta(mockCats) };
    return { data: res.data };
  } catch (error) {
    return { error };
  }
};

/**
 * Get all internal service items.
 * @returns {object[]}
 */
const getItems = async (params = {}) => {
  params = { markedAsHidden: false, ...params };

  try {
    const res = await axios.get(API.INTERNAL_SERVICE_ITEMS, {
      params,
      headers: {
        username: appService.getTenantId(),
        authorization: `Bearer ${getAccessToken()}`,
      },
    });

    // return { data: withMeta(mockItems.filter((i) => !params.menuType || params.menuType === i.menuType)) };
    res.data.data = res.data.data.map(_mapNewResponseToOldVm);
    return { data: res.data };
  } catch (error) {
    return { error };
  }
};

/**
 * Get a internal service item by id.
 * @param {string|number} id
 * @returns {object}
 */
const getItemById = async (id) => {
  try {
    const res = await axios.get(`${API.INTERNAL_SERVICE_ITEMS}/${id}`, {
      headers: {
        username: appService.getTenantId(),
        authorization: `Bearer ${getAccessToken()}`,
      },
    });
    // return { data: mockItems.find((i) => i._id == id) };
    // return { data: res.data.data };
    return { data: _mapNewResponseToOldVm(res.data.data) };
  } catch (error) {
    return { error };
  }
};

/**
 * Book an internal service.
 * @param {object} values
 * @returns {object}
 */
const bookItem = async (values) => {
  const data = { ...values, status: 'Booked' };

  try {
    const res = await axios.post(API.INTERNAL_SERVICE_BOOKINGS, data, {
      headers: {
        username: appService.getTenantId(),
        authorization: `Bearer ${getAccessToken()}`,
      },
    });

    // const { clientSettings } = appService.getTenantData();
    // if ( !clientSettings?.printerToken) return false
    // const printerToken = clientSettings?.printerToken

    // if (printerToken === PinterToken.GOKARNA.TOKEN) return false;

    // printReceipt(res.data.data)

    return { data: res.data };
  } catch (error) {
    return { error };
  }
};

/**
 * Cancel an internal service booking.
 * @param {string} bookingId
 */
const cancelBooking = async (bookingId) => {
  try {
    const res = await axios.put(
      `${API.INTERNAL_SERVICE_BOOKINGS}/cancel/${getUser()?.id}/${bookingId}`,
      { status: GUEST_REQ_STATUS.CANCELED },
      {
        headers: {
          username: appService.getTenantId(),
          authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );

    return { data: res?.data };
  } catch (error) {
    return { error };
  }
};

export { getCategories, getItems, getItemById, bookItem, cancelBooking , getOnlyValidTimeSlotInterval};
