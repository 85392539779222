import axios from "axios";
import { getValue } from "../utils/object";
import { getAccessToken } from "../utils/sessionManager";
import API from "../constants/api";
import { appService } from "./appService";

const translationCache = new Map();

export const getCachedTranslation = ({ text, targetLang }) => {
  const key = `${text}-${targetLang}`;
  return translationCache.get(key);
};

export const setCachedTranslation = ({ text, targetLang, translation }) => {
  const key = `${text}-${targetLang}`;
  translationCache.set(key, translation);
};

const access_token = getAccessToken();

const containsAlphabets = (text) => {
  const regex = /[a-zA-Z]/;
  return regex.test(text);
};

export const translateTextToTargetLanguage = async ({ text, targetLang }) => {
  try {
    if (!text || targetLang === "en" || !containsAlphabets(text)) return text;
    const cachedTranslation = getCachedTranslation({ text, targetLang });
    if (cachedTranslation) {
      return cachedTranslation;
    }

    const languageToTranslate = targetLang === "cn" ? "zh-CN" : targetLang;

    const payload = {
      text: text,
      languageToTranslate: languageToTranslate,
    };

    const response = await axios.post(
      `${API.TRANSLATION}/google-cloud`,
      payload,
      {
        headers: {
          authorization: `Bearer ${access_token}`,
          username: appService.getTenantId(),
        },
      }
    );

    const translation = getValue(response, "data.data");

    setCachedTranslation({ text, targetLang, translation });

    return translation;
  } catch (error) {
    console.error("Translation error:", error);
    return text;
  }
};
