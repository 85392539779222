import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import "./Spa.scss";
import { useState } from "react";
import { fetchSpa } from "../../services/profileServices";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const Spa = () => {
  const { spa, setSpa } = useContext(ProfileContext);
  const { t } = useTranslation();
  const translation = t("profile");
  const gTranslation = t("global");
  const selectedLang = getSelectedLanguage();

  // const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [translatedData, setTranslatedData] = useState();

  const fetchData = useCallback(async () => {
    try {
      if (!spa) {
        setIsLoading(true);
        const data = await fetchSpa();
        setSpa(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const translateTextBatch = async (texts, targetLang) => {
    return translateTextToTargetLanguage({ text: texts, targetLang });
  };

  const translateSpa = useCallback(async () => {
    if (!spa) return [];

    if (selectedLang === "en") {
      return spa.map((data) => ({
        ...data,
        treatments: getValue(data, "treatments").join(" - ").trim(),
      }));
    }

    const spaPromises = spa?.map(async (data) => {
      const description = getValue(data, "description")?.replace(/&/g, "and");
      const treatments = getValue(data, "treatments", [])
        ?.join(" - ")
        .replace(/&/g, "and");

      const [translatedDescription, translatedTreatments] = await Promise.all([
        // translateTextBatch(name, selectedLang),
        translateTextBatch(description, selectedLang),
        translateTextBatch(treatments, selectedLang),
      ]);

      return {
        ...data,
        description: translatedDescription,
        treatments: translatedTreatments,
      };
    });

    const tSpa = await Promise.all(spaPromises);

    return tSpa;
  }, [selectedLang, spa]);

  useEffect(() => {
    const fetchTranslatedFitness = async () => {
      const tSpa = await translateSpa();
      setTranslatedData(tSpa);
    };

    fetchTranslatedFitness();
  }, [translateSpa]);

  return (
    <section
      style={{
        maxWidth: "400px",
        margin: "auto",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha   "
    >
      <h1
        style={{ fontSize: "25px", fontWeight: 600 }}
        className="  mx-3 mt-3 mb-2"
      >
        {getValue(translation, "services.spa")}
      </h1>
      {/* <span
        className="mx-3 "
        style={{ fontSize: "14px", fontWeight: 700, letterSpacing: "2px" }}
      >
        {" "}
        {getValue(gTranslation, "noDataFound")}
      </span> */}
      {isLoading ? (
        <div className=" d-flex justify-content-center mt-5">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      ) : (
        <>
          {spa?.length < 0 ? (
            <div className=" d-flex justify-content-center mt-5">
              {getValue(gTranslation, "noDataFound")}
            </div>
          ) : (
            <article className=" mt-4 px-3 ">
              {translatedData?.map((item, index) => (
                <div
                  key={item?._id}
                  className="mb-3 all_properties cursor_pointer mx-3 p-3"
                >
                  <h4 className="my-2 ">{item?.name}</h4>
                  <p style={{ fontSize: "14px" }} className="mb-2">
                    {item?.description}
                  </p>
                  <span
                    className="text-uppercase"
                    style={{
                      fontSize: "14px",
                      letterSpacing: "1.2px",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {getValue(gTranslation, "treatmentAmenities")}
                  </span>

                  {getValue(item, "treatments")
                    .split("-")
                    ?.map((item, index) => (
                      <h4
                        key={index}
                        style={{
                          color: "#8f8f8f",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        className="my-1"
                      >
                        {item}
                      </h4>
                    ))}
                </div>
              ))}
            </article>
          )}
        </>
      )}
    </section>
  );
};

export default Spa;
