import React, { createContext, useReducer } from 'react';
import foodReduce from '../reducers/foodReduce';
import * as Service from '../services/food';
import FOOD_ACTION from '../actions/food';

const initialState = {
  currentCartIDs: [],
  cartCount: 0,
  list: [],
  meta: { size: 10, total: 0, page: 1 },
};

export const FoodContext = createContext(initialState);
export const FoodContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(foodReduce, initialState);

  async function listFoods(params) {
    const res = await Service.listFoods(params);
    if (res) dispatch({ type: FOOD_ACTION.LIST_FOODS, data: res });
    return res;
  }

  function getFoodDetails(foodId) {
    return Service.getFoodDetails(foodId);
  }

  function saveOrderRequest(payload) {
    return Service.saveOrderRequest(payload);
  }

  function listMenuTypes() {
    return Service.listMenuTypes();
  }
  function menuDetails() {
    return Service.menuDetails();
  }

  return (
    <FoodContext.Provider
      value={{
        list: state.list,
        cartCount: state.cartCount,
        pagination: state.pagination,
        currentCartIDs: state.currentCartIDs,
        listFoods,
        getFoodDetails,
        saveOrderRequest,
        listMenuTypes,
        dispatch,
        menuDetails,
      }}
    >
      {children}
    </FoodContext.Provider>
  );
};
