import moment from "moment";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import MaterialTimePicker from "@mui/lab/MobileTimePicker";
import React, { useState, useContext } from "react";
import { useToasts } from "react-toast-notifications";

import RequestImg from "../../assets/images/icon/request-item.svg";
import ReportImg from "../../assets/images/icon/report.svg";
import ScheduleImg from "../../assets/images/icon/schedule.svg";
import ReqLaundryImg from "../../assets/images/icon/request-laundry.svg";
import ReqLuggageImg from "../../assets/images/icon/request-luggage.svg";
import ReqCleaningImg from "../../assets/images/icon/request-cleaning.svg";

import ModalWrapper from "../global/ModalWrapper";
import { appService } from "../../services/appService";
import { DynamicRoutes } from "../../constants/routes";
import { GUEST_REQ_TYPE, SERVICE_TYPE } from "../../constants";
import { HotelServiceContext } from "../../contexts/HotelServiceContext";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";

const TIME_FORMAT = "h:mm a";
const ISO_FORMAT = "YYYY-MM-DD";

export default function Index() {
  const { clientSettings = {} } = appService.getTenantData();
  const { addToast } = useToasts();
  const { submitGuestRequest } = useContext(HotelServiceContext);
  const { t } = useTranslation();
  const translation = t("services");

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalNote, setModalNote] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [serviceTime, setServiceTime] = useState("");
  const [requestType, setRequestType] = useState("");
  const [guestReqType, setGuestReqType] = useState("");
  const [requestMessage, setRequestMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedMessage, setSubmittedMessage] = useState("");
  const [dateFieldLabel, setDateFieldLabel] = useState("");
  const [timeFieldLabel, setTimeFieldLabel] = useState("");

  const handleDateInputChange = (e) => {
    setServiceDate(e.target.value);
  };

  const handleTimeInputChange = (value) => {
    const time = value ? value.format(TIME_FORMAT) : "";
    setServiceTime(time);
  };

  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleWakeupCall = (e) => {
    setIsSubmitted(false);
    setIsSubmitting(false);
    setSubmittedMessage(
      getValue(translation, 'schedule.successMsg')
    );

    e.preventDefault();
    setModalTitle(getValue(translation, "schedule.description"));
    setRequestType(SERVICE_TYPE.WAKEUP_CALL);
    setGuestReqType(GUEST_REQ_TYPE.FRONT_OFFICE);
    setRequestMessage("Please wake me up at");
    setServiceTime("");
    setDateFieldLabel("");
    setTimeFieldLabel("");
    handleToggleModal();
  };

  const handleCleaningRequest = (e) => {
    setIsSubmitted(false);
    setIsSubmitting(false);
    setSubmittedMessage(
      getValue(translation, 'cleaning.successMsg')
    );

    e.preventDefault();
    setModalTitle(getValue(translation, "cleaning.description"));
    setModalNote(clientSettings.cleaningRequestNote);
    setRequestType(SERVICE_TYPE.CLEANING);
    setGuestReqType(GUEST_REQ_TYPE.HOUSE_KEEPING);
    setRequestMessage("Please clean up my room at");
    setServiceTime("");
    setDateFieldLabel(getValue(translation, 'cleaning.serviceDate'));
    setTimeFieldLabel(getValue(translation, 'cleaning.serviceTime'));
    handleToggleModal();
  };

  const handleLaundryService = (e) => {
    setIsSubmitted(false);
    setIsSubmitting(false);
    setSubmittedMessage(
      getValue(translation, 'laundry.successMsg')

    );

    e.preventDefault();
    setModalTitle(getValue(translation, "laundry.description"));
    setModalNote(clientSettings.laundryServiceNote);
    setRequestType(SERVICE_TYPE.LAUNDRY);
    setGuestReqType(GUEST_REQ_TYPE.HOUSE_KEEPING);
    setRequestMessage("Please pick up my laundry at");
    setServiceTime("");
    setDateFieldLabel("");
    setTimeFieldLabel("");
    handleToggleModal();
  };

  const handleLuggagePick = (e) => {
    setIsSubmitted(false);
    setIsSubmitting(false);
    setSubmittedMessage(
      getValue(translation, 'luggage.successMsg')

    );

    e.preventDefault();
    setModalTitle(getValue(translation, "luggage.description"));
    setModalNote(null);
    setRequestType(SERVICE_TYPE.LUGGAGE);
    setGuestReqType(GUEST_REQ_TYPE.FRONT_OFFICE);
    setRequestMessage("Please pick up my luggage at");
    setServiceTime("");
    setDateFieldLabel("");
    setTimeFieldLabel("");
    handleToggleModal();
  };

  const sendGuestRequest = (payload) => {
    setIsSubmitting(true);
    submitGuestRequest(payload)
      .then(() => {
        setIsSubmitted(true);
        setServiceDate("");
      })
      .catch(() => {
        addToast("Internal server error!", {
          autoDismiss: true,
          appearance: "error",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (!serviceTime) {
      return addToast("Please select service time!", {
        autoDismiss: true,
        appearance: "error",
      });
    }
    const payload = {
      type: guestReqType,
      requestType,
      details: `${requestMessage} ${moment(new Date(serviceTime)).format(
        TIME_FORMAT
      )}, ${serviceDate}`,
    };
    return sendGuestRequest(payload);
  };

  return (
    <>
      <ModalWrapper
        title={modalTitle}
        isOpen={openModal}
        toggleModal={handleToggleModal}
        handleSubmit={handleModalSubmit}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
        submittedMessage={submittedMessage}
      >
        <>
          {!!modalNote && (
            <small className="d-block mx-3 mt-3">Note: {modalNote}</small>
          )}
          <div className="popup_content">
            <div className="pop_input ha_login_input">
              <p>
                {dateFieldLabel ||
                  getValue(translation, "schedule.serviceDate")}
              </p>
              <label />
              <input
                onChange={handleDateInputChange}
                min={moment().format(ISO_FORMAT)}
                name="serviceDate"
                type="date"
                placeholder="Choose date"
                value={serviceDate}
                required
              />
            </div>
            <div className="pop_input ha_login_input no-fieldset full-width-material-input">
              <p>
                {timeFieldLabel ||
                  getValue(translation, "schedule.serviceTime")}
              </p>
              <MaterialTimePicker
                value={serviceTime}
                onChange={(v) => setServiceTime(v)}
                renderInput={(params) => <TextField {...params} />}
                inputFormat={TIME_FORMAT}
                displayStaticWrapperAs="mobile"
              />
            </div>
          </div>
        </>
      </ModalWrapper>
      <section className="services_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div className="services_box">
                <div className="items_gap">
                  <div className="services_items">
                    <div className="services_content">
                      <Link to={DynamicRoutes.RequestItem}>
                        <img src={RequestImg} alt="icon" />
                        <h4 className="fs-inherit text-bold">
                          {getValue(translation, "requestItem.title")}
                        </h4>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="items_gap">
                  <div className="services_items">
                    <div className="services_content">
                      <Link to={DynamicRoutes.IssueReport}>
                        <img src={ReportImg} alt="icon" />
                        <h4 className="fs-inherit text-bold">
                          {getValue(translation, "requestMaintenance.title")}
                        </h4>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </div>
                {!clientSettings.hideWakeupCall && (
                  <div className="items_gap">
                    <div className="services_items">
                      <div className="services_content">
                        <Link
                          to="#wakeup_call"
                          onClick={(e) => handleWakeupCall(e)}
                        >
                          <img src={ScheduleImg} alt="icon" />
                          <h4 className="fs-inherit text-bold">
                            {getValue(translation, "schedule.title")}
                          </h4>
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                <div className="items_gap">
                  <div className="services_items">
                    <div className="services_content">
                      <Link
                        to="#laundry"
                        onClick={(e) => handleLaundryService(e)}
                      >
                        <img src={ReqLaundryImg} alt="icon" />
                        <h4 className="fs-inherit text-bold">
                          {getValue(translation, "laundry.title")}
                        </h4>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </div>
                {!clientSettings.hideLuggagePickup && (
                  <div className="items_gap">
                    <div className="services_items">
                      <div className="services_content">
                        <Link
                          to="#luggage"
                          onClick={(e) => handleLuggagePick(e)}
                        >
                          <img src={ReqLuggageImg} alt="icon" />
                          <h4 className="fs-inherit text-bold">
                            {getValue(translation, "luggage.title")}
                          </h4>
                          <i className="fa fa-angle-right" aria-hidden="true" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                <div className="items_gap">
                  <div className="services_items">
                    <div className="services_content">
                      <Link
                        to="#cleaning_request"
                        onClick={(e) => handleCleaningRequest(e)}
                      >
                        <img src={ReqCleaningImg} alt="icon" />
                        <h4 className="fs-inherit text-bold">
                          {getValue(translation, "cleaning.title")}
                        </h4>
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
