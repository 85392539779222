import React from "react";
import { Link } from "react-router-dom";

import { appService } from "../../services/appService";

export default function AltLayout(props) {
  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <header className="header_area">
        <div className="header-row">
          <div className="logo pl_logo py-3">
            <div className="right_angle">
              <Link to="#back" onClick={() => appService.goBack()}>
                <i className="fa fa-angle-left" aria-hidden="true" />
              </Link>
            </div>
          </div>
        </div>
      </header>
      {props.children}
    </>
  );
}
