import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";

const CHATBOT_SERVER_URL = "http://localhost:8000/customer";

export default function Faq() {
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const handleMessageInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleMessageSubmit = (e) => {
    e.preventDefault();
    let msg = { type: "right_chat", message: inputMessage };
    setInputMessage("");
    setMessages([...messages, msg]);
    setTimeout(() => {
      testReply();
    }, 3000);
  };

  const testReply = () => {
    let msg = { type: "left_chat", message: "Hey buddy ;)" };
    setMessages([...messages, msg]);
  };

  useEffect(() => {
    const socket = socketIOClient(CHATBOT_SERVER_URL);
    socket.on("FromAPI", (data) => {
      let msg = { type: "right_chat", message: data };
      setMessages((m) => [...m, msg]);
    });

    return () => socket.disconnect();
  }, []);

  return (
    <>
      <section className="chat_area">
        <div className="chating_box">
          <div className="chating_content">
            <ul>
              {messages.length > 0 &&
                messages.map((msg, i) => {
                  return (
                    <li key={i + 1} className={`${msg.type}_items`}>
                      <div className={`${msg.type}_text`}>
                        <p>{msg.message}</p>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <form onSubmit={handleMessageSubmit}>
            <div className="text_box">
              <div className="text_input">
                <input
                  value={inputMessage}
                  onChange={handleMessageInputChange}
                  type="text"
                  placeholder="Type message"
                  required
                />
              </div>
              <div className="send_button">
                <button type="submit">
                  <i className="fa fa-paper-plane" aria-hidden="true" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
