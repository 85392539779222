import { useCallback, useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchOurProperties } from "../../services/profileServices";
import { useEffect } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { Spinner } from "react-bootstrap";
import NotFound from "../../assets/images/img/no-image-available.png";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";

const ViewAllProperties = () => {
  const { properties, setProperties } = useContext(ProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const translation = t("profile");

  const fetchData = useCallback(async () => {
    try {
      if (!properties) {
        setIsLoading(true);
        const data = await fetchOurProperties();
        setProperties(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <section
      style={{
        maxWidth: "400px",
        margin: "auto",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha   "
    >
      <h1
        style={{ fontSize: "25px" }}
        className="my-1 font-weight-bold mx-3 my-3"
      >
        {getValue(translation, 'ourProperties')}
      </h1>
      {isLoading ? (
        <div className=" d-flex justify-content-center mt-5">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      ) : (
        <article className=" mt-3 px-3">
          {properties?.map((property, index) => (
            <div key={index} className="mb-3 all_properties">
              <img
                src={property?.locationImage ?? NotFound}
                alt={property?.name}
                className="img-fluid"
              />
              <h4 className="my-2 mx-2">{property?.title}</h4>
              <p className="mb-2 mx-2 d-flex align-items-center">
                <IoLocationOutline />
                <span className="ml-1">{property?.location}</span>
              </p>
            </div>
          ))}
        </article>
      )}
    </section>
  );
};

export default ViewAllProperties;
