import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingScreen = () => (
  <div className="container">
    <div id="loading-screen" className="row ">
      <div className="col-sm-4 logo_spinner_container">
        <h1 id="splash_logo">Avendi</h1>
        <div className="loader">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      </div>
    </div>
  </div>
);

export default LoadingScreen;
export { LoadingScreen };
