import { useMemo } from "react";

export default function MenuCategory(props) {
  const { list, currentMenuType, menuListDetails, handleMenuCategoryClick } =
    props;

  const sortedMenuItems = useMemo(
    () => [...menuListDetails].sort((a, b) => a.position - b.position),
    [menuListDetails]
  );

  return (
    <>
      {sortedMenuItems?.map((item, index) => (
        <li
          key={item?._id}
          onClick={(e) =>
            handleMenuCategoryClick(e, item?.foodAndBevCategoryName)
          }
          className={
            currentMenuType === item.foodAndBevCategoryName
              ? "ui-tab ui-tabs-active ui-state-active whitespace-nowrap pointer-events-none d-flex align-items-center"
              : "whitespace-nowrap"
          }
          id={`foodMenuCategoryItem_${index}`}
        >
          <a to="#">
            <div className="d-flex flex-column align-items-center">
              {item?.translatedCategory}
              {/* <label className="fs-1">{item?.foodAndBevCategoryName}</label> */}
            </div>
          </a>
        </li>
      ))}
    </>
  );
}
