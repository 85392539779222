import ReactGA from 'react-ga4';

const useAnalyticsEventTracker = () => {
  const eventTracker = ({ eventName, category, action, label }) => {
    const params = {
      category: category,
      action: action,
      label: label,
    };
    ReactGA.event(eventName, params);
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
