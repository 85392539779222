import axios from 'axios';

import API from '../constants/api';
import { appService } from './appService';
import { getAccessToken, getUser } from '../utils/sessionManager';
// import printReceipt from '../utils/printReceipt';
// import { PinterToken } from '../constants/enum';

const access_token = getAccessToken();
const currentUser = getUser();


export async function submitGuestRequest(payload) {
  payload.guestId = currentUser._id;
  const res = await axios.post(`${API.GUEST_REQUEST}`, payload, {
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });

  // const { clientSettings } = appService.getTenantData();
  // if (!clientSettings?.printerToken) return false;
  // const printerToken = clientSettings?.printerToken;

  // if (printerToken === PinterToken.GOKARNA.TOKEN) return false;

  // printReceipt(res.data.data)

  return res.data;
}
export async function submitGuestFeedback(payload) {
  payload.userName = currentUser.lastName;
  payload.roomNumber = currentUser.roomNumber;
  const res = await axios.post(`${API.USER_FEEDBACK}`, payload, {
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  return res.data;
}

export async function listMyRequestStatus() {
  const res = await axios({
    url: `${API.GUEST_REQUEST}/${currentUser._id}/guest`,
    method: 'Get',
    headers: {
      username: appService.getTenantId(),
      authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
}
