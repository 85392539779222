import "./InternalServices.scss";

import { useState, useEffect, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { Link, useHistory, useLocation } from "react-router-dom";

import LoadMore from "../global/LoadMore";

import { DynamicRoutes } from "../../constants/routes";
import { appService } from "../../services/appService";
import { getOrgDetails } from "../../utils/sessionManager";
import * as service from "../../services/internalServiceService";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const CATEGORY_QUERY_KEY = "category";

const InternalServices = (props = {}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryCategory = queryParams.get(CATEGORY_QUERY_KEY);

  const { t } = useTranslation();
  const translation = t("global");
  const selectedLang = getSelectedLanguage();

  const [category, setCategory] = useState(queryCategory || "");
  const [categoryList, setCategoryList] = useState([]);
  const [translatedCategoryList, setTranslatedCategoryList] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [translatedCategoryItems, setTranslatedCategoryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const orgDetails = getOrgDetails();

  const handleCategoryClick = (e, _categoryRef) => {
    e.preventDefault();
    if (_categoryRef.internalServiceCategoryName === category) return;

    setCurrentPage(1);
    setCategory(_categoryRef.internalServiceCategoryName);
  };

  const loadMoreItems = () => {
    const totalPages = Math.ceil(itemsCount / pageSize);
    if (totalPages <= currentPage) {
      addToast(getValue(translation, "noMoreData"), {
        autoDismiss: true,
        appearance: "info",
      });
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  /**
   * componentDidUpdate
   */
  useEffect(() => {
    const getInternalServiceItemsByCategory = async () => {
      if (!category) return;

      const params = {
        menuType: category,
        page: currentPage,
      };

      const {
        data: { data, meta },
        error,
      } = await service.getItems(params);

      if (error) {
        alert("Can`t fetch items for current category.");
        return;
      }

      if (params.menuType) {
        const _location = {
          ...location,
          search: `?${CATEGORY_QUERY_KEY}=${encodeURIComponent(
            params.menuType
          )}`,
        };
        history.replace(_location);
      }
      setCurrentPage(meta.page);
      setItemsCount(meta.total);
      setPageSize(meta.size);
      setCategoryItems((currentItems) => {
        if ((currentItems[0] || {}).menuType === (data[0] || {}).menuType) {
          // currentPage was changed i.e. Show More clicked
          return [...currentItems, ...data];
        }

        return [...data];
      });
    };

    getInternalServiceItemsByCategory();
  }, [category, currentPage]);

  /**
   * componentDidMount
   */
  useEffect(() => {
    const getCategories = async () => {
      const { data: { data } = {}, error } = await service.getCategories();
      if (error) {
        alert("Can`t fetch categories");
        return;
      }

      const initialCategory = data[0] || {};
      setCategoryList(data);
      setCategory(queryCategory || initialCategory.internalServiceCategoryName);
    };

    getCategories();
  }, []);

  const translateTextBatch = async (texts, targetLang) => {
    return translateTextToTargetLanguage({ text: texts, targetLang });
  };

  const translateCategoryList = useCallback(async () => {
    if (!categoryList) return [];
    if (selectedLang === "en") {
      return categoryList.map((data) => ({
        ...data,
        translatedCategory: data.internalServiceCategoryName,
      }));
    }

    const category = categoryList
      .map((cate) => cate.internalServiceCategoryName)
      .join(" ** ")
      .replace(/&/g, "and");

    const [translatedCategoryName] = await Promise.all([
      translateTextBatch(category, selectedLang),
    ]);

    const translatedCategoryArray = translatedCategoryName.split("**");

    return categoryList.map((item, index) => ({
      ...item,
      translatedCategory: translatedCategoryArray[index]?.trim(),
    }));
  }, [selectedLang, categoryList]);

  const translateCategoryItems = useCallback(async () => {
    if (!categoryItems) return [];

    if (selectedLang === "en") {
      return categoryItems;
    }

    const categoryPromises = categoryItems.map(async (item) => {
      const [
        // translatedCategoryName,
        translatedDescription,
      ] = await Promise.all([
        // translateTextBatch(name, selectedLang),
        translateTextBatch(getValue(item, "description").replace(/&/g, "and"), selectedLang),
      ]);

      return {
        ...item,
        description: translatedDescription,
      };
    });

    const tCategoryItems = await Promise.all(categoryPromises);

    return tCategoryItems;
  }, [selectedLang, categoryItems]);

  useEffect(() => {
    const fetchTranslatedCategoryList = async () => {
      const tCategoryItems = await translateCategoryList();
      setTranslatedCategoryList(tCategoryItems);
    };

    fetchTranslatedCategoryList();
  }, [translateCategoryList]);

  useEffect(() => {
    const fetchTranslatedCategoryItemList = async () => {
      const tCategoryItems = await translateCategoryItems();
      setTranslatedCategoryItems(tCategoryItems);
    };

    fetchTranslatedCategoryItemList();
  }, [translateCategoryItems]);

  // const indexOfDinner = categoryList.findIndex(
  //   (item) => item.internalServiceCategoryName === "Snooker"
  // );
  // if (indexOfDinner !== -1) {
  //   const removedItem = categoryList.splice(indexOfDinner, 1)[0];
  //   categoryList.splice(2, 0, removedItem);

  // }

  // function moveItems(array, moves) {
  //   // Validate moves
  //   for (const [oldIndex, newIndex] of moves) {
  //     if (
  //       oldIndex < 0 ||
  //       oldIndex >= array.length ||
  //       newIndex < 0 ||
  //       newIndex >= array.length
  //     ) {

  //       return;
  //     }
  //   }

  //   // Sort moves array by descending oldIndex to avoid conflicts
  //   moves.sort((a, b) => b[0] - a[0]);

  //   // Apply moves
  //   for (const [oldIndex, newIndex] of moves) {
  //     // Remove the item from the old index
  //     const [item] = array.splice(oldIndex, 1);

  //     // Insert the item at the new index
  //     array.splice(newIndex, 0, item);
  //   }
  // }

  // Move 'c' to index 0 and 'e' to index 1

  // moveItems(categoryList, [
  //   [5, 1],
  //   // [4, 1],
  // ]);

  return (
    <section className="mtabs_items_area" id="page__internal-service">
      {/* background color can be changed from AppRouter.js  component  */}
      <div className="container">
        <div className="row"></div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
            <div id="mtabs">
              <div className="tabs_menu new_page_menu">
                <ul
                  style={{ gap: "5px" }}
                  className="scrollbar-hidden scroll-x flex-nowrap whitespace-nowrap m-0 "
                >
                  {translatedCategoryList.map((item) => (
                    <li
                      key={item._id}
                      style={{ gap: "10px" }}
                      onClick={(e) => handleCategoryClick(e, item)}
                      className={
                        category === item.internalServiceCategoryName
                          ? "pl-0 ui-tab ui-tabs-active ui-state-active  d-flex align-items-center "
                          : "pl-0"
                      }
                    >
                      <a href="#lunch" className="">
                        {item.translatedCategory}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
            <div className="mtabs">
              <div className="all_tabs_items">
                <div className="mt-">
                  <div className="mt-">
                    {/* single items */}
                    {translatedCategoryItems &&
                    translatedCategoryItems.length > 0
                      ? translatedCategoryItems.map((item) => {
                          // TODO: Implement CSS solution
                          // const truncatedDescription = `${item.description.substr(
                          //   0,
                          //   200
                          // )} ${item.description.length > 200 ? "..." : ""}`;
                          return (
                            <div key={item._id} className="itemspadding">
                              <div>
                                <div className="avendi-card">
                                  <div className="color-black d-flex justify-content-between">
                                    <div>
                                      <h4 className="fw-  title">
                                        {item.name}
                                      </h4>
                                      <p className="description">
                                        {item?.description?.slice(0, 70)}
                                        {item?.description?.length > 70 &&
                                          "..."}
                                      </p>
                                    </div>
                                    <div className="d-flex ml-1">
                                      <div className="">
                                        <h4
                                          style={{ fontSize: "12px" }}
                                          className="fw-nomal price"
                                        >
                                          {orgDetails?.currency}{" "}
                                          {item.price?.toLocaleString()}
                                        </h4>
                                        <p style={{ fontSize: "11px" }}>
                                          {item?.duration[0]?.time}
                                        </p>
                                      </div>
                                      <div className="ml-2">
                                        <Link
                                          className="btn-book btn-outline-dark"
                                          to={appService.getFullPath(
                                            DynamicRoutes.BookInternalService,
                                            { id: item._id }
                                          )}
                                        >
                                          {getValue(translation, "bookNow")}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : getValue(translation, "itemNotAvailable")}
                    {/* End single item */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {itemsCount > 10 ? (
          <LoadMore handleLoadMoreClick={loadMoreItems} />
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export { InternalServices };
export default InternalServices;
