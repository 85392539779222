import './StatusProgressBar.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FaDotCircle } from 'react-icons/fa';
import { GoDotFill } from "react-icons/go";

const StatusProgressBar = ({ statuses = [], current = statuses[0] }) => {
  const indexOfCurrent = statuses.indexOf(current);

  return (
    <div className="progress-status-bar">
      { statuses.map((status, i) => (
        <React.Fragment key={i}>
          <div
            className={`status-checkpoint ${i <= indexOfCurrent ? 'done' : ''}`}
          >
            {
              status === current
                ? <GoDotFill size={25} className="current" />
                : <FaDotCircle size={20} />
            }
          </div>
          {(i + 1) !== statuses.length && (
            <div className={`dotted-line ${i < indexOfCurrent ? 'done' : ''}`}>{}</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

StatusProgressBar.propTypes = Object.freeze({
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  current: PropTypes.string.isRequired,
});
StatusProgressBar.defaultProps = Object.freeze({
  statuses: [1, 2, 3],
  current: 1,
});

export { StatusProgressBar };
export default { StatusProgressBar };
