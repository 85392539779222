import moment from "moment";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MaterialTimePicker from "@mui/lab/MobileTimePicker";
import { TextField } from "@mui/material";
import { useContext, useState, useEffect, useCallback } from "react";

import {
  removeFromCart,
  getCartItems,
  clearAllCartItems,
  getOrgDetails,
} from "../../utils/sessionManager";
import { FoodContext } from "../../contexts/FoodContext";
import FOOD_ACTIONS from "../../actions/food";

import { DynamicRoutes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const orgDetails = getOrgDetails();

const TIME_FORMAT = "h:mm a";

export default function Checkout() {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const translation = t("checkout");
  const selectedLang = getSelectedLanguage();

  const { saveOrderRequest, dispatch } = useContext(FoodContext);
  const [orderItems, setOrderItems] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [valueAddedTax, setValueAddedTax] = useState(0);

  const [translatedData, setTranslatedData] = useState([]);

  const fetchCurrentCartIds = () => {
    const data = getCartItems() || [];
    const IDs = data.length ? data.map((item) => item.foodMenuId) : [];
    dispatch({ type: FOOD_ACTIONS.SET_CURRENT_CART, data: IDs });
  };

  const calculateTotalOrderAmount = (data) => {
    const _subTotal = (data || []).reduce(
      (total, item) => total + parseFloat(item.price) * parseInt(item.quantity),
      0
    );
    const _serviceCharge = (orgDetails?.serviceCharge / 100) * _subTotal;
    const totalAfterServiceCharge = _subTotal + _serviceCharge;
    const _valueAddedTax = (orgDetails.vat / 100) * totalAfterServiceCharge;
    const grandTotal = _subTotal + _serviceCharge + _valueAddedTax;

    setSubTotal(_subTotal.toFixed(2));
    setServiceCharge(_serviceCharge?.toFixed(2));
    setValueAddedTax(_valueAddedTax.toFixed(2));
    setTotalOrderAmount(grandTotal.toFixed(2));
  };

  const fetchOrderItems = () => {
    const data = getCartItems() || [];
    calculateTotalOrderAmount(data);
    setOrderItems(data);
  };

  const handleRemoveFromCart = (e, foodMenuId) => {
    e.preventDefault();
    removeFromCart(foodMenuId);
    dispatch({ type: FOOD_ACTIONS.SET_CART_COUNT, data: null });
    fetchCurrentCartIds();
    fetchOrderItems();
  };

  const createOrderDetail = () => {
    let details = "";
    if (orderItems.length < 1) {
      return addToast("Please add an item to cart!", {
        autoDismiss: true,
        appearance: "error",
      });
    }
    orderItems.forEach((order) => {
      details += `${order.quantity} ${order.name} - Customization: ${
        order.selectedOption || "N/A"
      } - Instruction: ${order.instruction ? order.instruction : "N/A"},`;
    });
    return details;
  };

  const createItemsPayload = (items) =>
    items.map(
      ({ name: orderName, selectedOption: customization, ...rest }) => ({
        ...rest,
        orderName,
        customization,
      })
    );

  const placeOrder = (payload) => {
    setIsSubmitting(true);
    saveOrderRequest(payload)
      .then(() => {
        setIsSubmitted(true);
        clearAllCartItems();
        addToast("Order placed successfully", {
          autoDismiss: true,
          appearance: "success",
        });
      })
      .catch(() =>
        addToast("Internal server error!", {
          autoDismiss: true,
          appearance: "error",
        })
      )
      .finally(() => setIsSubmitting(false));
  };

  const handlePlaceOrderClick = () => {
    if (deliveryOption === "SPECIFY" && !deliveryTime) {
      return addToast("Please select delivery time!", {
        autoDismiss: true,
        appearance: "error",
      });
    }
    const _details = createOrderDetail();
    const payload = {
      completionBy:
        !deliveryTime || typeof deliveryTime === "string"
          ? deliveryTime || "ASAP"
          : moment(deliveryTime).format(TIME_FORMAT),
      details: `${_details}Total Amount: ${totalOrderAmount}`,
      orders: createItemsPayload(orderItems),
      totalAmount: totalOrderAmount,
      serviceCharge,
      serviceChargePercentage: orgDetails?.serviceCharge,
      vat: valueAddedTax,
      vatPercentage: orgDetails.vat,
    };
    placeOrder(payload);
  };

  const handleDeliveryTimeChange = (e) => {
    const { value } = e.target;
    if (value === "SPECIFY") {
      setDeliveryOption(value);
      setDeliveryTime("");
      return;
    }
    setDeliveryOption("");
    setDeliveryTime(value);
  };

  useEffect(fetchOrderItems, []);

  // const translateTextBatch = async (texts, targetLang) => {
  //   return translateTextToTargetLanguage({ text: texts, targetLang });
  // };

  // const translateFitness = useCallback(async () => {
  //   if (!orderItems) return [];

  //   if (selectedLang === "en") {
  //     return orderItems;
  //   }

  //   const name = orderItems.map((data) => data.name).join(" ** ");

  //   const [translatedNames] = await Promise.all([
  //     translateTextBatch(name, selectedLang),
  //   ]);

  //   const translatedNamesArray = translatedNames.split("**");

  //   return orderItems.map((data, index) => ({
  //     ...data,
  //     name: translatedNamesArray[index]?.trim(),
  //   }));
  // }, [selectedLang, orderItems]);

  // useEffect(() => {
  //   const fetchTranslatedFitness = async () => {
  //     const tOrderItems = await translateFitness();
  //     setTranslatedData(tOrderItems);
  //   };

  //   fetchTranslatedFitness();
  // }, [translateFitness]);

  return (
    <>
      <section className="mtabs_items_area" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div className="mitems_area">
                <div className="new_page_tabs_area">
                  <h1>{getValue(translation, "orderItems")}</h1>
                </div>
                {isSubmitted ? (
                  <div className="report_message_box ml-4">
                    <p>
                      {getValue(translation, "successMsg")}
                      <br />
                      <br />
                      <br />
                      {getValue(translation, "checkStatus")}&nbsp;
                      <Link
                        to={`${DynamicRoutes.Dashboard}#my_status`}
                        className="color-inherit"
                      >
                        <u>{getValue(translation, "clickHere")}</u>
                      </Link>
                      .
                    </p>

                    <span className="ha_button">
                      <Link to={DynamicRoutes.FoodMenu}>
                        {getValue(translation, "goBack")}
                      </Link>
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="mitems_box box_shadow">
                      <div className="mitems_list">
                        <ul>
                          {orderItems.length
                            ? orderItems.map((item) => (
                                <li key={item.foodMenuId}>
                                  <div className="d-inline-block">
                                    <a
                                      title="Remove from cart"
                                      style={{ color: "red" }}
                                      className="d-inline-block"
                                      href="#remove"
                                      onClick={(e) =>
                                        handleRemoveFromCart(e, item.foodMenuId)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </a>
                                    <h4 className="ml-3 d-inline-block">
                                      {item.quantity} X
                                    </h4>
                                  </div>
                                  <div className="left_mitems">
                                    <h4>{item.name}</h4>
                                  </div>
                                  <div className="right_mitems">
                                    <h4>{item.price}</h4>
                                  </div>
                                </li>
                              ))
                            : getValue(translation, "cartEmpty")}
                        </ul>
                      </div>
                      {orderItems.length > 0 && (
                        <>
                          <div className="page_list mitems_list">
                            <ul>
                              <li>
                                <span>{getValue(translation, "subTotal")}</span>
                                <span>
                                  {orgDetails?.currency || "USD"}
                                  &nbsp;
                                  {subTotal || 0}
                                </span>
                              </li>
                              {/* <li>
                                    <span>Service Charge ({orgDetails.serviceCharge}%)</span>
                                    <span>
                                      { orgDetails.currency || 'USD' }
                                      &nbsp;
                                      { serviceCharge || 0 }
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      VAT ({orgDetails.vat}%)
                                    </span>
                                    <span>
                                      { orgDetails.currency || 'USD' }
                                      &nbsp;
                                      { valueAddedTax || 0 }
                                    </span>
                                  </li> */}
                            </ul>
                          </div>
                          <div className="page_list total_amount">
                            <div className="select-option-area">
                              <div
                                style={{ marginTop: 10, marginBottom: 10 }}
                                className="select-option  text-center"
                              >
                                <select
                                  defaultValue="DEFAULT"
                                  style={{ width: "100%", height: 40 }}
                                  onChange={handleDeliveryTimeChange}
                                  className="form_input_color"
                                >
                                  <option value="DEFAULT" disabled hidden>
                                    Select your delivery time
                                  </option>
                                  <option value="ASAP">ASAP</option>
                                  <option value="In an hour">In an hour</option>
                                  <option value="SPECIFY">Specify time</option>
                                </select>
                              </div>
                              {deliveryOption === "SPECIFY" && (
                                <div className="pop_input ha_login_input no-fieldset full-width-material-input">
                                  <MaterialTimePicker
                                    value={deliveryTime}
                                    onChange={(v) => setDeliveryTime(v)}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    inputFormat={TIME_FORMAT}
                                    displayStaticWrapperAs="mobile"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="page_list total_amount">
                            <ul>
                              <li>
                                <span>{getValue(translation, "total")}</span>
                                <span>
                                  {orgDetails ? orgDetails?.currency : "USD"}{" "}
                                  {totalOrderAmount}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="single_pbutton">
                            <button
                              type="button"
                              onClick={handlePlaceOrderClick}
                              disabled={isSubmitting}
                            >
                              {getValue(translation, "placeOrder")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
