import React from 'react';
import { appService } from '../../services/appService';

export default function Index() {
  const tenant = appService.getTenantData();
  const { clientSettings } = tenant;
  const chatTitle = `${tenant.hotelName} - ${tenant.hotelBranch}`;

  return (
    <df-messenger
      chat-icon={tenant.imageUrl}
      intent="WELCOME"
      chat-title={chatTitle}
      agent-id={clientSettings.faqAgentId}
      expand="true"
      language-code="en"
    />
  );
}
