import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import DefaultFoodImg from '../../assets/images/img/no-image-available.png';
import { categoryList, data } from './data';

import { DynamicRoutes } from '../../constants/routes';
import { appService } from '../../services/appService';

const CURRENCY_NAME = 'AED';

export default function Index() {
  const [currentCategory, setCurrentCategory] = useState('attractions');
  const [travelDeskList, setTravelDeskList] = useState([]);

  const handleCategoryClick = (e, category) => {
    e.preventDefault();
    setCurrentCategory(category);
  };

  const fetchTravelDesk = () => {
    const filter = data.filter((item) => item.category === currentCategory);
    setTravelDeskList(filter);
  };

  useEffect(fetchTravelDesk, [currentCategory]);
  return (
    <>
      <section className="mtabs_items_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div id="mtabs">
                <div className="tabs_menu new_page_menu">
                  <ul>
                    {categoryList.map((item) => {
                      return (
                        <li
                          key={item.id}
                          onClick={(e) => handleCategoryClick(e, item.value)}
                          className={
                            currentCategory === item.value
                              ? 'ui-tab ui-tabs-active ui-state-active'
                              : ''
                          }
                        >
                          <Link className="txt-capitalize" to="#lunch">
                            {item.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="all_tabs_items">
                  <div className="new_page_tabs_area">
                    <h1 className="txt-capitalize">{currentCategory}</h1>
                    <div className="new_page_items_box">
                      {/* single items */}
                      {travelDeskList && travelDeskList.length > 0
                        ? travelDeskList.map((item) => {
                            return (
                              <div key={item.id} className="items_padding">
                                <Link to={appService.getFullPath(DynamicRoutes.TravelDeskDetails, { id: item.id })}>
                                  <div className="new_page_items box_shadow">
                                    <div className="new_page_left">
                                      <h1>{item.name}</h1>
                                      <p>{item.description}</p>
                                      <div className="time_items">
                                        <h4>
                                          {CURRENCY_NAME} {''}
                                          {parseFloat(item.price) || 0}
                                        </h4>
                                        <h3>None</h3>
                                      </div>
                                    </div>
                                    <div className="new_page_right">
                                      <img
                                        src={
                                          item.image
                                            ? item.image
                                            : DefaultFoodImg
                                        }
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })
                        : 'No item available!'}
                      {/* End single item */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
