import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { HotelServiceContext } from "../../contexts/HotelServiceContext";
import Rating from "./Rating";
import { DynamicRoutes } from "../../constants/routes";
import { getValue } from "../../utils/object";
import { useTranslation } from "react-i18next";

const Feedback = () => {
  const { t } = useTranslation();
  const translation = t("feedback");
  const gTranslation = t("global");
  const feedBackTranslation = t("feedbackTopic");

  const { submitGuestFeedback } = useContext(HotelServiceContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const { addToast } = useToasts();
  const [cleanliness, setCleanliness] = useState(0);
  const [experiences, setExperiences] = useState(0);
  const [activities, setActivities] = useState(0);
  const [staffServices, setStaffServices] = useState(0);
  const [amenities, setAmenities] = useState(0);
  const [moneyValue, setMoneyValue] = useState(0);
  const [accommodation, setAccommodation] = useState(0);
  const [wildlifeExperience, setWilLifeExperience] = useState(0);
  const [overallExperience, setOverAllExperience] = useState(0);

  const sendFeedback = (payload) => {
    setIsSubmitting(true);
    submitGuestFeedback(payload)
      .then(() => {
        setIsSubmitted(true);
        setMessage("");
        setCleanliness(0);
        setExperiences(0);
        setActivities(0);
        setStaffServices(0);
        setMoneyValue(0);
        setAmenities(0);
      })
      .catch(() => {
        addToast("Internal server error!", {
          autoDismiss: true,
          appearance: "error",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      comment: message,
      ratings: [
        {
          type: "Cleanliness",
          rating: cleanliness,
        },
        {
          type: "Service and Hospitality",
          rating: staffServices,
        },
        {
          type: "Accommodation",
          rating: accommodation,
        },
        {
          type: "Culinary Experience",
          rating: experiences,
        },
        {
          type: "Wildlife Experience",
          rating: wildlifeExperience,
        },
        {
          type: "Activities and Excursion",
          rating: activities,
        },
        {
          type: "Facilities and Amenities",
          rating: amenities,
        },
        {
          type: "Value for Money",
          rating: moneyValue,
        },
        {
          type: "Overall Experience",
          rating: overallExperience,
        },
      ],
    };

    return sendFeedback(payload);
  };

  return (
    <section
      style={{
        maxWidth: "400px",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha container-sm mt-3 py-3 px-4 "
    >
      {!isSubmitted ? (
        <h1 style={{ fontSize: "25px" }} className="my-1 font-weight-normal">
          {getValue(translation, "title")}
        </h1>
      ) : (
        <h1 style={{ fontSize: "25px" }} className="my-1 font-weight-normal">
          {getValue(translation, "feedback")}
        </h1>
      )}

      {isSubmitted ? (
        <div className="report_message_box ha_button">
          <p>{getValue(translation, "successMsg")}</p>

          <Link to={DynamicRoutes.ClientRoot}>
            {getValue(gTranslation, "goBack")}
          </Link>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              fontSize: "14px",
            }}
            className=" my-5 px-2 text-dark"
          >
            <div className="d-flex my-1 justify-content-between">
              <span>{getValue(feedBackTranslation, "cleanliness")}</span>
              <Rating rating={cleanliness} setRating={setCleanliness} />
            </div>

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "serviceAndHospitality")}</span>
              <Rating rating={staffServices} setRating={setStaffServices} />
            </div>

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "accommodation")}</span>
              <Rating rating={accommodation} setRating={setAccommodation} />
            </div>


            <div className=" d-flex  my-1 justify-content-between">
              <span>{getValue(feedBackTranslation, "culinaryExperience")}</span>
              <Rating rating={experiences} setRating={setExperiences} />
            </div>

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "wildlifeExperience")}</span>
              <Rating rating={wildlifeExperience} setRating={setWilLifeExperience} />
            </div>

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "activitiesExcursion")}</span>
              <Rating rating={activities} setRating={setActivities} />
            </div>
           

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "facilitiesAmenities")}</span>
              <Rating rating={amenities} setRating={setAmenities} />
            </div>

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "valueForMoney")}</span>
              <Rating rating={moneyValue} setRating={setMoneyValue} />
            </div>

            <div className=" d-flex my-1  justify-content-between">
              <span>{getValue(feedBackTranslation, "overallExperience")}</span>
              <Rating rating={overallExperience} setRating={setOverAllExperience} />
            </div>
          </div>
          <p className=" mb-2 text-dark">
            {getValue(translation, "inputLabel")}
          </p>

          <div className="message_input ha_login_input">
            <textarea
              name="requestItem"
              placeholder={getValue(translation, "inputPlaceholder")}
              rows={7}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <div className="ha_button mssg_button">
            <input
              onClick={handleFormSubmit}
              type="submit"
              value={getValue(translation, "submit")}
              readOnly
              // disabled={isSubmitting || !message.trim().length}
            />
          </div>
        </>
      )}
    </section>
  );
};

export default Feedback;

// updated translation 101