import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import CrossIcon from "../../assets/images/icon/cross.svg";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";

export default function ModalWrapper(props) {
  const {
    title,
    children,
    isOpen,
    toggleModal,
    handleSubmit,
    isSubmitted = false,
    isSubmitting = false,
    submittedMessage = "",
    bigHeading,
    size,
  } = props;

  const { t } = useTranslation();
  const translation = t("global");

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal.bind(null)}
        modalClassName="avendi-modal"
        className="popup_area show"
        centered
        size={size}
      >
        {isSubmitted ? (
          <>
            <ModalHeader>
              <h3>{getValue(translation, "done")}</h3>
              <div className="report_message_box ha_button">
                <p>{submittedMessage}</p>

                <Link to="#" onClick={toggleModal.bind(null)}>
                  {getValue(translation, "goBack")}
                </Link>
              </div>
            </ModalHeader>
          </>
        ) : (
          <>
            <ModalHeader>
              <div className="close_icon">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  title="Cancel"
                  onClick={toggleModal.bind(null)}
                >
                  <img src={CrossIcon} alt="Icon" />
                </button>
              </div>
              {bigHeading ? (
                <h6 className="color-black">{title}</h6>
              ) : (
                <span className="modal-title">{title || "Modal Title"}</span>
              )}
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
              <ModalBody>{children || "No child elements supplied."}</ModalBody>
              <ModalFooter className="avendi-modal">
                <div className="ha_button pop_button">
                  <input
                    type="submit"
                    value={getValue(translation, "confirmBtn")}
                    readOnly
                    disabled={isSubmitting}
                  />
                </div>
              </ModalFooter>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
}

ModalWrapper.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
