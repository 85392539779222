// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";

/**
 * Get route with tenant path param.
 * @param {string} partialRoute - Route without tenant information eg: /route
 * @returns {string} - eg: /:tenantId/route
 */
const getFullRoute = (partialRoute) => `/:tenantId${partialRoute}`;

/**
 * Frontend routes.
 */
const Routes = Object.freeze({
  NotFound: "/404",
  ClientRoot: getFullRoute("/"),
  Dashboard: getFullRoute("/"),
  App: getFullRoute("/*"),

  Home: getFullRoute("/home"),
  Login: getFullRoute("/login"),
  // profile
  Profile: getFullRoute("/profile"),
  ViewallProperties: getFullRoute("/profile/all-properties"),
  ViewallDineAndDrinks: getFullRoute("/profile/dinning"),
  Spa: getFullRoute("/services/spa"),
  Fitness: getFullRoute("/services/fitness"),
  ServiceCategory: getFullRoute("/services/:pathname"),

  // Guest requests
  MyRequestDetails: getFullRoute("/my-requests/:id"),

  // Services
  Services: getFullRoute("/services"),

  // Checkout
  Checkout: getFullRoute("/checkout"),

  // faq
  Faq: getFullRoute("/faq"),

  // chat
  Chat: getFullRoute("/chat"),

  // Food Menu
  FoodMenu: getFullRoute("/foodmenu"),
  FoodMenuDetails: getFullRoute("/foodmenu/:id"),

  // Issue Report
  IssueReport: getFullRoute("/issue-report"),

  // Request Item
  RequestItem: getFullRoute("/request-item"),

  // TravelDesk
  TravelDesk: getFullRoute("/traveldesk"),
  TravelDeskDetails: getFullRoute("traveldesk/:id"),

  // Museum
  Museum: getFullRoute("/museum"),
  Feedback: getFullRoute("/feedback"),
  MuseumTourBook: getFullRoute("/museum/book-a-tour"),
  MuseumExplore: getFullRoute("/museum/explore"),
  MuseumItemDetails: getFullRoute("/museum/explore/:id"),
  MuseumPackages: getFullRoute("/museum/packages"),
  MuseumPackageDetails: getFullRoute("/museum/packages/:id"),

  // Internal Services
  InternalServices: getFullRoute("/internal-services"),
  BookInternalService: getFullRoute("/internal-services/:id/book"),
});

/**
 * Dynamic routes i.e. with tenant information.
 * Copying with lodash, just to get the type & autocompletion support.
 * Else JSON.parse(JSON.stringify(Routes)) would do.
 */
const DynamicRoutes = _.clone(Routes);

/**
 * Public app routes i.e can be accessed without authentication.
 */
const PublicRoutes = Object.freeze([
  Routes.ClientRoot,
  Routes.NotFound,
  Routes.Login,
  Routes.Home,
]);

/**
 * Anonymous routes i.e. doesn't require tenant/client info.
 * Also, these have a separate design i.e. no/different top nav bar.
 */
const AnonymousRoutes = Object.freeze([Routes.NotFound]);

/**
 * Generate dynamic route with tenant information.
 * @param {string} tenantId
 */
const generateDynamicRoutes = (tenantId) => {
  Object.keys(Routes).forEach((routeName) => {
    const currentStaticRoute = Routes[routeName];
    DynamicRoutes[routeName] = currentStaticRoute.replace(
      ":tenantId",
      tenantId
    );
  });
};

export default Routes;
export {
  Routes,
  PublicRoutes,
  AnonymousRoutes,
  DynamicRoutes,
  generateDynamicRoutes,
};
