export function mergeAndRemoveDuplicateByKeyname(
  array1 = [],
  array2 = [],
  keyName
) {
  const array3 = [...array1, ...array2];
  // Return unique array on the basis of object key name.
  return [
    ...new Map(array3.map((item) => [item[`${keyName}`], item])).values(),
  ];
}

export function checkIfKeyExistInArrayOfObject(inputArray, keyName) {
  return inputArray.filter((o) => Object.prototype.hasOwnProperty.call(o, keyName)).length > 0;
}

export function filterByFoodMenuId(inputArray, foodMenuId) {
  return inputArray.filter((item) => item.foodMenuId === foodMenuId);
}

export function removeDuplicateFromArray(inputArray, keyName) {
  return [...new Map(inputArray.map((item) => [item[keyName], item])).values()];
}
