import "./Profile.scss";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DownArrow from "../../assets/images/icon/down-arrow.svg";
import NotFound from "../../assets/images/img/no-image-available.png";
import Fitness from "../../assets/images/icon/fitness.png";
import Spa from "../../assets/images/icon/spa.png";
import Attraction from "../../assets/images/icon/attraction.png";
import Accessibility from "../../assets/images/icon/accessibilty.png";
import Amenties from "../../assets/images/icon/amenties.png";
import Bus from "../../assets/images/icon/bus.png";
import Properties from "./Properties";
import DineAndDrink from "./DineAndDrink";
import { FiChevronRight } from "react-icons/fi";
import { useState } from "react";
import { fetchProfileData } from "../../services/profileServices";
import { Spinner } from "react-bootstrap";
import { DynamicRoutes } from "../../constants/routes";
import { Link } from "react-router-dom";
import { appService } from "../../services/appService";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const Profile = () => {
  const { hotelDetails, setHotelDetails } = useContext(ProfileContext);
  const { t } = useTranslation();
  const translation = t("profile");
  const selectedLang = getSelectedLanguage();

  const [isLoading, setIsLoading] = useState(false);
  const [textLength, setTextLength] = useState(500);
  const { hotelName, address, bannerImage, description, messageHeading } =
    hotelDetails || {};

  const [translatedHotelDetails, setTranslatedHotelDetails] = useState({
    description: description,
    messageHeading: messageHeading,
  });

  const goToItemPage = (pathname) => {
    appService.goTo(DynamicRoutes.ServiceCategory, { pathname });
  };

  const fetchHotelProfile = useCallback(async () => {
    try {
      if (!hotelDetails) {
        setIsLoading(true);
        const response = await fetchProfileData();
        setIsLoading(false);
        setHotelDetails(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchHotelProfile();
  }, [fetchHotelProfile]);

  useEffect(() => {
    const translateHotelProfile = async () => {
      if (selectedLang === "en") {
        setTranslatedHotelDetails({
          description: description,
          messageHeading: messageHeading,
        });
        return;
      }

      const [translatedMsgHeading, translatedDescription] = await Promise.all([
        translateTextToTargetLanguage({
          text: messageHeading,
          targetLang: selectedLang,
        }),
        translateTextToTargetLanguage({
          text: description,
          targetLang: selectedLang,
        }),
      ]);

      setTranslatedHotelDetails({
        messageHeading: translatedMsgHeading,
        description: translatedDescription,
      });
    };
    translateHotelProfile()
  }, [selectedLang, description, messageHeading]);

  return (
    <section
      style={{
        maxWidth: "400px",
        margin: "auto",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha   "
    >
      {isLoading ? (
        <div className=" d-flex justify-content-center mt-5">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      ) : (
        <>
          <div className="proile_banner">
            <div>
              {bannerImage && (
                <img
                  src={bannerImage ?? NotFound}
                  alt="Hotel Ambassador"
                  className=""
                />
              )}
            </div>
          </div>
          <div className="profile_article">
            <h1
              style={{
                font: "26px important",
                fontWeight: 800,
                fontFamily: "IBM Plex Sans, sans-serif",
              }}
            >
              {hotelName}
            </h1>
            <p className="mt-3">{address}</p>
          </div>
          <div className="profile_article">
            <h2>{getValue(translation, "welcomeMessage")}</h2>
            <h3 className="mt-1">{translatedHotelDetails.messageHeading}</h3>
            <p className="mt-3">{translatedHotelDetails.description?.slice(0, textLength)}</p>

            {description?.length > 500 && (
              <div className="d-flex mt-2  align-items-center">
                {textLength == 500 ? (
                  <>
                    <h5 className="" onClick={() => setTextLength(4000)}>
                      {getValue(translation, "readMore")}
                    </h5>
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      src={DownArrow}
                    />
                  </>
                ) : (
                  <>
                    <h5 className="" onClick={() => setTextLength(500)}>
                      {getValue(translation, "showLess")}
                    </h5>
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        transform: "rotate(180deg)",
                      }}
                      src={DownArrow}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <Properties translation={translation} />
          <DineAndDrink translation={translation} />
          <div className="our_service">
            <h2>{getValue(translation, "ourServices")}</h2>
            <p className="mt-2 ">
              {getValue(translation, "ourServiceDescription")}
            </p>
          </div>
          <div
            style={{
              marginTop: "15px",
              backgroundColor: "white",
              margin: " 15px ",
              borderRadius: "10px",
            }}
            className=" "
          >
            <div style={{ width: "100%" }} className="border-bottom">
              <Link
                to={DynamicRoutes.Fitness}
                style={{ width: "100%", padding: " 20px 15px" }}
                className="d-flex align-items-center d-flex justify-content-between "
              >
                <div className="d-flex align-items-center ">
                  <img src={Fitness} className="profile_icons" />
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {getValue(translation, "services.fitness")}
                  </span>
                </div>
                <FiChevronRight className="text-dark" />
              </Link>
            </div>
            <div style={{ width: "100%" }} className="border-bottom">
              <Link
                to={DynamicRoutes.Spa}
                style={{ width: "100%", padding: " 20px 15px" }}
                className="d-flex align-items-center d-flex justify-content-between "
              >
                <div className="d-flex align-items-center ">
                  <img src={Spa} className="profile_icons" />
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {getValue(translation, "services.spa")}
                  </span>
                </div>
                <FiChevronRight className="text-dark" />
              </Link>
            </div>
            <div style={{ width: "100%" }} className="border-bottom">
              <div
                onClick={() => goToItemPage("attractions")}
                style={{ width: "100%", padding: " 20px 15px" }}
                className="d-flex align-items-center d-flex justify-content-between cursor_pointer"
              >
                <div className="d-flex align-items-center ">
                  <img src={Attraction} className="profile_icons" />
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {getValue(translation, "services.attractions")}
                  </span>
                </div>
                <FiChevronRight className="text-dark" />
              </div>
            </div>
            <div style={{ width: "100%" }} className="border-bottom">
              <div
                onClick={() => goToItemPage("parking")}
                style={{ width: "100%", padding: " 20px 15px" }}
                className="d-flex align-items-center d-flex justify-content-between "
              >
                <div className="d-flex align-items-center ">
                  <img src={Bus} className="profile_icons" />
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {getValue(translation, "services.parking")}
                  </span>
                </div>
                <FiChevronRight className="text-dark" />
              </div>
            </div>
            <div style={{ width: "100%" }} className="border-bottom">
              <div
                onClick={() => goToItemPage("accessibility")}
                style={{ width: "100%", padding: " 20px 15px" }}
                className="d-flex align-items-center d-flex justify-content-between "
              >
                <div className="d-flex align-items-center ">
                  <img src={Accessibility} className="profile_icons" />
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {getValue(translation, "services.accessibility")}
                  </span>
                </div>
                <FiChevronRight className="text-dark" />
              </div>
            </div>
            <div style={{ width: "100%" }} className="border-bottom">
              <div
                onClick={() => goToItemPage("amenities")}
                style={{ width: "100%", padding: " 20px 15px" }}
                className="d-flex align-items-center d-flex justify-content-between "
              >
                <div className="d-flex align-items-center ">
                  <img src={Amenties} className="profile_icons" />
                  <span
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {getValue(translation, "services.amenities")}
                  </span>
                </div>
                <FiChevronRight className="text-dark" />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Profile;
