export const PinterToken = {
  GOKARNA: {
    PRINTER_ID: 1,
    TOKEN: '',
  },
  // Do not enable unless you have the printer
  // AVENDI:{
  //   PRINTER_ID:2,
  //   TOKEN:""
  // },
  SHANKAR: {
    PRINTER_ID: 3,
    TOKEN: '',
  },
};
