/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from '../actions/food';
import { getCartItems } from '../utils/sessionManager';

export default (state, action) => {
  const res = action.data;
  switch (action.type) {
    case `${ACTIONS.LIST_FOODS}`:
      return {
        ...state,
        list: res.data,
        meta: {
          size: parseInt(res.meta.size),
          total: parseInt(res.meta.total),
          page: parseInt(res.meta.page),
        },
      };

    case ACTIONS.SET_CART_COUNT:
      const cartItems = getCartItems();
      return {
        ...state,
        cartCount: cartItems ? cartItems.length : 0,
      };

    case ACTIONS.SET_CURRENT_CART:
      return {
        ...state,
        currentCartIDs: action.data,
      };

    default:
      return state;
  }
};
