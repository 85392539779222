export function addToCart(items) {
  localStorage.setItem("cartItems", JSON.stringify(items));
}

export function removeFromCart(cartItemId) {
  let results = [];
  let itemsInCart = getCartItems();
  if (itemsInCart && itemsInCart.length) {
    results = itemsInCart.filter((item) => item.foodMenuId !== cartItemId);
  }
  addToCart(results);
}

export function logoutUser() {
  const recentTenants =
    JSON.parse(localStorage.getItem("recent_tenants")) || [];
  const selectedLanguage = localStorage.getItem("avendi-lang");
  localStorage.clear();
  localStorage.setItem("recent_tenants", JSON.stringify(recentTenants));
  localStorage.setItem("avendi-lang", selectedLanguage);
}

export function clearAllCartItems() {
  localStorage.setItem("cartItems", null);
}

export function getCartItems() {
  if (
    localStorage.getItem("cartItems") &&
    Object.keys(localStorage.getItem("cartItems")).length
  ) {
    return JSON.parse(localStorage.getItem("cartItems"));
  }
  return null;
}

export function getUser() {
  const currentUser = localStorage.getItem("currentUser");
  if (currentUser && Object.keys(currentUser).length) {
    return JSON.parse(currentUser);
  }
  return null;
}

export function saveUser(userData) {
  localStorage.setItem("currentUser", JSON.stringify(userData));
}

export function saveTenant(tenantName) {
  localStorage.setItem("currentTenant", tenantName);
}

export function getTenant() {
  if (localStorage.getItem("currentTenant")) {
    return localStorage.getItem("currentTenant");
  }
  return null;
}

export function saveAccessToken(accessToken) {
  localStorage.setItem("accessToken", accessToken);
}

export function getAccessToken() {
  if (localStorage.getItem("accessToken")) {
    return localStorage.getItem("accessToken");
  }
  return null;
}

export function getOrgDetails() {
  const orgDetails = localStorage.getItem("orgDetails");
  if (orgDetails && Object.keys(orgDetails).length) {
    return JSON.parse(orgDetails);
  }
  return null;
}

export function saveOrgDetails(org) {
  localStorage.setItem("orgDetails", JSON.stringify(org));
}

export function setFirstLogin(flag) {
  localStorage.setItem("firstLogin", flag);
}

export function getFirstLogin() {
  const isFirstLogin = localStorage.getItem("firstLogin");
  return isFirstLogin || false;
}
