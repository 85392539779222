import React from "react";
import Header from "../partials/header";
import Footer from "../partials/Footer";

export default function HomePageLayout(props) {
  return (
    <>
      {/* <Header /> */}
      {props.children}
      {!!props.showFooter && <Footer />}
    </>
  );
}
