import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { appService } from "../../services/appService";
import { DynamicRoutes } from "../../constants/routes";
import { getOrgDetails } from "../../utils/sessionManager";
import DefaultFoodImg from "../../assets/images/img/no-image-available.png";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";

const orgDetails = getOrgDetails();

/* eslint-disable react/prop-types */
const FoodItem = ({ food, showTiming = false, startTimes, endTimes }) => {
  const { t } = useTranslation();
  const translation = t("foodMenu", {
    menuType: getValue(food, "menuType", ""),
  });

  const startTime = startTimes[0]
    ? moment(startTimes[0])?.format("HH:mm:ss")
    : "--";
  const endTime = endTimes[0] ? moment(endTimes[0])?.format("HH:mm:ss") : "--";
  const [disableMenu, setDisableMenu] = useState();
  const [openModal, setOpenModal] = useState(false);

  const tenantData = appService.getTenantData();
  const foodItemNote = useMemo(() => {
    if (!showTiming) return null;
    return tenantData?.clientSettings?.guestAppFoodMenuNote?.(food.menuType);
  }, [showTiming, tenantData, food]);

  useEffect(() => {
    const date = new Date();
    const currentHour = date.getHours();
    const currentMinute = date.getMinutes();

    const str1 = startTime.split(":");
    const str2 = endTime.split(":");
    let currentTotal = currentHour * 3600 + currentMinute * 60;
    const startTimeTotal = parseInt(str1[0] * 3600 + str1[1] * 60);
    let endTimeTotal = parseInt(str2[0] * 3600 + str2[1] * 60);

    if (startTimeTotal <= endTimeTotal) {
      // If the end time is on the same day
      if (currentTotal >= startTimeTotal && currentTotal <= endTimeTotal) {
        setDisableMenu(true);
      } else {
        setDisableMenu(false);
      }
    } else {
      // If the end time is on the next day
      if (currentTotal >= startTimeTotal || currentTotal <= endTimeTotal) {
        setDisableMenu(true);
      } else {
        setDisableMenu(false);
      }
    }
  }, [startTime, endTime]);
  // console.log(disableMenu);

  return (
    <>
      {openModal && (
        <div className=" popup_modal ">
          <div className=" bg-white  modal_content ">
            <h1 className="my-2">
              {getValue(translation, "itemNotAvailable")}
            </h1>
            <p>
              {getValue(translation, "menuServedBetween")}{" "}
              <strong>
                {" "}
                {startTimes[0]
                  ? moment(startTimes[0]).format("hh:mm a")
                  : "--"}{" "}
                {getValue(translation, "and")}{" "}
                {endTimes[0] ? moment(endTimes[0]).format("hh:mm a") : "--"}
              </strong>
              . {getValue(translation, "exploreOtherMenuItem")}
            </p>
            <div className="ha_goback mt-3 mb-2">
              <input
                onClick={() => setOpenModal(false)}
                type="button"
                value="Go Back"
                readOnly
              />
            </div>
          </div>
          <div onClick={() => setOpenModal(!openModal)} className="modal_bg " />
        </div>
      )}

      <div key={food._id} className="items_padding">
        {disableMenu === false ? (
          <>
            <div
              onClick={() => setOpenModal(true)}
              className="avendi-card mt-2"
            >
              <div className="new_page_items">
                <div className="new_page_left">
                  <h1>{food.name}</h1>
                  <p>{food.description}</p>
                  <div className="time_items">
                    <h4>
                      {orgDetails ? orgDetails.currency : "USD"} &nbsp;
                      {parseFloat(food.price) || 0}
                    </h4>
                    <h3>{food.categories}</h3>
                  </div>
                </div>
                <div className="new_page_right">
                  <img
                    src={food.image ? food.image : DefaultFoodImg}
                    alt="img"
                  />
                </div>
              </div>
              {foodItemNote && (
                <p className="justify mt-3">
                  <u>
                    <strong>{getValue(translation, "note")}:</strong>
                  </u>{" "}
                  {food.note}
                </p>
              )}
            </div>
          </>
        ) : (
          <Link
            to={appService.getFullPath(DynamicRoutes.FoodMenuDetails, {
              id: food._id,
            })}
          >
            <div className="avendi-card mt-2">
              <div className="new_page_items">
                <div className="new_page_left">
                  <h1>{food.name}</h1>
                  <p>{food.description}</p>
                  <div className="time_items">
                    <h4>
                      {orgDetails ? orgDetails.currency : "USD"} &nbsp;
                      {parseFloat(food.price) || 0}
                    </h4>
                    <h3>{food.categories}</h3>
                  </div>
                </div>
                <div className="new_page_right">
                  <img
                    src={food.image ? food.image : DefaultFoodImg}
                    alt="img"
                  />
                </div>
              </div>
              {foodItemNote && (
                <p className="justify mt-3">
                  <u>
                    <strong>{getValue(translation, "note")}:</strong>
                  </u>{" "}
                  {food.note}
                </p>
              )}
            </div>
          </Link>
        )}
      </div>
    </>
  );
};
/* eslint-enable react/prop-types */

export default FoodItem;
export { FoodItem };
