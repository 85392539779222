import AttractionImg from '../../assets/images/img/attract.jpg';
import OutdoorImg from '../../assets/images/img/outdoors.jpg';
import CultureImg from '../../assets/images/img/culture.jpg';
import NightLifeImg from '../../assets/images/img/nightlife.jpg';

export const categoryList = [
  { id: 1, label: 'Attractions', value: 'attractions' },
  { id: 2, label: 'Outdoors', value: 'outdoors' },
  { id: 3, label: 'Cultural', value: 'cultural' },
  { id: 4, label: 'Nightlife', value: 'nightlife' },
];

export const data = [
  {
    id: 101,
    name: 'Atlantis Aquaventure Water Park',
    image: AttractionImg,
    description:
      'Feel the frenzy of excitement as you ride a collection of record-breaking rides.Take a break from the thrills and relax on 700 meters of beautiful private beach.',
    price: 85,
    category: 'attractions',
  },
  {
    id: 102,
    name: 'IMG Worlds of Adventure',
    image: OutdoorImg,
    description:
      'Explore over 1.5 million square feet of playground with 4 epic adventure zones featuring themes from Marvel, Cartoon Network, IMG Boulevard and The Lost Valley Join your favorite superheroes on the action-packed thrill rides.',
    price: 30,
    category: 'attractions',
  },
  {
    id: 103,
    name: 'Wild Wadi Water Park',
    image: CultureImg,
    description:
      'Jump the queue for unlimited access to 30 waterslides and rides from the tame to the terrifying Face the 32m Jumeirah Sceirah, the tallest and fastest slide in the Middle East for a heart-stopping experience Kids will love Juha’s Lagoon, an interactive play area with water guns, racing slides, and huge dumping bucketsDiscover the park’s unique upward-moving water network - no need to queue or even leave the water!',
    price: 189,
    category: 'attractions',
  },
  {
    id: 104,
    name: 'Dubai Garden Glow Ticket',
    image: NightLifeImg,
    description:
      'Dubai Garden Glow is the world’s largest unique theme park with unusual, never-before-seen concepts  Enter the largest glow-in-the-dark garden made of 10 million energy saving bulbs with recycled luminous fabric. Explore the biggest Dinosaur park that displays more than 100 animatronic dinosaurs with interactive labs',
    price: 55,
    category: 'attractions',
  },
];
