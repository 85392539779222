import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { AppRouter } from './AppRouter';
import { History } from '../utils/History';
import { FoodContextProvider } from '../contexts/FoodContext';
import { HotelServiceContextProvider } from '../contexts/HotelServiceContext';
import { ProfileProvider } from '../contexts/ProfileContext';

function App() {
  return (
    <ToastProvider placement="bottom-right">
      <FoodContextProvider>
        <ProfileProvider>
          <HotelServiceContextProvider>
            <Router history={History}>
              <AppRouter />
            </Router>
          </HotelServiceContextProvider>
        </ProfileProvider>
      </FoodContextProvider>
    </ToastProvider>
  );
}

export default App;
