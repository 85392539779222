/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";

import FoodImg from "../../assets/images/img/no-image-available.png";
import { FoodContext } from "../../contexts/FoodContext";
import {
  addToCart,
  getCartItems,
  removeFromCart,
  getOrgDetails,
} from "../../utils/sessionManager";
import { mergeAndRemoveDuplicateByKeyname } from "../../utils";
import FOOD_ACTIONS from "../../actions/food";

import { appService } from "../../services/appService";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const MAX_ORDER = 15;
const MIN_ORDER = 1;
const orgDetails = getOrgDetails();

export default function Details(props) {
  const foodId = props.match.params.id;
  const { addToast } = useToasts();
  const selectedLang = getSelectedLanguage();

  const { t } = useTranslation();

  const translation = t("foodMenuDetails", {
    MAX_ORDER: MAX_ORDER,
    MIN_ORDER: MIN_ORDER,
  });

  const { getFoodDetails, dispatch, currentCartIDs } = useContext(FoodContext);
  const newCartItems = [];

  const [foodDetails, setFoodDetails] = useState(null);

  const [translatedFoodDetails, setTranslatedFoodDetails] = useState({
    name: "",
    description: "N/A",
  });

  const [foodCount, setFoodCount] = useState(1); // Cart count
  const [menuOptionSelect, setMenuOptionSelect] = useState("");
  const [specialInstruction, setSpecialInstruction] = useState("");
  const [optionPrice, setOptionPrice] = useState(null);

  const fetchCurrentCartIds = () => {
    const data = getCartItems() || [];
    const IDs = data.length ? data.map((item) => item.foodMenuId) : [];
    dispatch({ type: FOOD_ACTIONS.SET_CURRENT_CART, data: IDs });
  };

  const getExistingCart = () => {
    let data = getCartItems();
    if (!data) return [];
    return data;
  };

  const handleRemoveFromCart = (e, foodMenuId) => {
    e.preventDefault();
    removeFromCart(foodMenuId);
    dispatch({ type: FOOD_ACTIONS.SET_CART_COUNT, data: null });
    fetchCurrentCartIds();
  };

  const saveToMyCart = (payload) => {
    const existingCartItems = getExistingCart();
    newCartItems.push(payload);
    const mergedData = mergeAndRemoveDuplicateByKeyname(
      existingCartItems,
      newCartItems,
      "foodMenuId"
    );
    addToCart(mergedData);
    dispatch({ type: FOOD_ACTIONS.SET_CART_COUNT });
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    const defaultPrice = foodDetails.price || 0;
    const payload = {
      name: foodDetails.name,
      foodMenuId: foodDetails._id,
      price: optionPrice ? optionPrice : defaultPrice,
    };
    payload.selectedOption = `${menuOptionSelect}`;
    payload.instruction = specialInstruction;
    payload.quantity = foodCount;
    saveToMyCart(payload);
    appService.goBack();
  };

  const fetchFoodDetails = () => {
    getFoodDetails(foodId)
      .then((data) => {
        setFoodDetails(data.data);
        setTranslatedFoodDetails({
          name: getValue(data, "data.name"),
          description: getValue(data, "data.description", "N/A"),
        });
      })
      .catch(() => {
        addToast("Internal server error!", {
          autoDismiss: true,
          appearance: "error",
        });
      });
  };

  const foodCountUp = () => {
    if (foodCount >= MAX_ORDER) {
      return addToast(getValue(translation, "maxOrderAllowed"), {
        autoDismiss: true,
        appearance: "error",
      });
    }
    setFoodCount(foodCount + 1);
  };

  const foodCountDown = () => {
    if (foodCount <= MIN_ORDER) {
      return addToast(getValue(translation, "minOrderAllowed"), {
        autoDismiss: true,
        appearance: "error",
      });
    }
    setFoodCount(foodCount - 1);
  };

  const handleMenuOptionChange = (e, price) => {
    setOptionPrice(price);
    setMenuOptionSelect(e.target.value);
  };

  const handleSpecialInstructionChange = (e) => {
    setSpecialInstruction(e.target.value);
  };

  const translateFoodDetails = async () => {
    if (selectedLang === "en") {
      setTranslatedFoodDetails({
        name: getValue(foodDetails, "name"),
        description: getValue(foodDetails, "description"),
      });
      return;
    }

    const [translatedDescription] = await Promise.all([
      // translateTextToTargetLanguage({
      //   text: getValue(foodDetails, "name"),
      //   targetLang: selectedLang,
      // }),
      translateTextToTargetLanguage({
        text: getValue(foodDetails, "description"),
        targetLang: selectedLang,
      }),
    ]);

    setTranslatedFoodDetails((prev) => ({
      ...prev,
      description: translatedDescription,
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchFoodDetails, []);
  useEffect(fetchCurrentCartIds, []);

  useEffect(() => {
    translateFoodDetails();
  }, [selectedLang, foodDetails]);

  return (
    <>
      <section className="single_page_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div className="single_fpage_area">
                <div className="single_fbanner">
                  <img
                    src={
                      foodDetails && foodDetails.image
                        ? foodDetails.image
                        : FoodImg
                    }
                    alt="food"
                  />
                </div>
                <div className="single_page_box">
                  <div className="items_gap">
                    <div className="box_shadow single_page">
                      <div className="single_page_content">
                        <h1>{translatedFoodDetails.name}</h1>
                        <h1>
                          {orgDetails ? orgDetails.currency : "USD"} &nbsp;
                          {(foodDetails && foodDetails.price) || 0}
                        </h1>
                        <p>{translatedFoodDetails.description}</p>
                      </div>
                    </div>
                  </div>

                  {foodDetails &&
                    foodDetails.options &&
                    foodDetails.options.length > 0 && (
                      <div className="items_gap">
                        <div className="box_shadow single_page">
                          <div className="cooking_style">
                            <h1>{getValue(translation, "selectOption")}</h1>
                          </div>
                          <div className="cooking_style_list">
                            <ul>
                              {foodDetails.options.map((item) => (
                                <li key={item.id}>
                                  <input
                                    type="radio"
                                    id={item.id}
                                    name="selectOption"
                                    value={item.name}
                                    onChange={(e) =>
                                      handleMenuOptionChange(e, item.price)
                                    }
                                  />
                                  <label htmlFor={item.id}>
                                    {item.name} - {item.price || 0}
                                  </label>
                                  <div className="check" />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="items_gap">
                    <div className="box_shadow single_page">
                      <div className="single_page_content add_class2">
                        <h1>{getValue(translation, "specialInstruction")}</h1>

                        <textarea
                          value={specialInstruction}
                          name="specialInstruction"
                          placeholder={getValue(
                            translation,
                            "specialInstructionPlaceholder"
                          )}
                          rows="1"
                          onChange={handleSpecialInstructionChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* HIDDEN ADDON DIV */}
                  <div className="items_gap">
                    <div className="box_shadow single_page">
                      <div className="quantity_counter_area">
                        <div className="quantity_counter">
                          <button
                            onClick={foodCountDown}
                            id="counter_decrement"
                            className="decrement"
                          />
                          <input
                            id="counter_value"
                            className="value"
                            type="number"
                            value={foodCount}
                            readOnly
                          />
                          <button
                            onClick={foodCountUp}
                            id="counter_increment"
                            className="increment"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="single_pbutton">
                    {foodDetails &&
                    currentCartIDs &&
                    currentCartIDs.indexOf(foodDetails._id) > -1 ? (
                      <Link
                        to="#remove_from_cart"
                        onClick={(e) => {
                          handleRemoveFromCart(e, foodDetails._id);
                        }}
                      >
                        {getValue(translation, "removeItem")}
                      </Link>
                    ) : (
                      <Link
                        to="#add_to_cart"
                        onClick={(e) => handleAddToCart(e)}
                      >
                        {getValue(translation, "addItem")}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
