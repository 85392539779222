import axios from 'axios';
import qs from 'query-string';

import API from '../constants/api';
import { appService } from './appService';
import { GUEST_REQ_TYPE, SERVICE_TYPE } from '../constants';
import { getAccessToken, getUser } from '../utils/sessionManager';
// import printReceipt from '../utils/printReceipt';

const access_token = getAccessToken();

export async function saveOrderRequest(payload) {
  const currentUser = getUser();
  payload.guestId = currentUser._id;
  payload.requestType = SERVICE_TYPE.FOOD_BEV;
  payload.type = GUEST_REQ_TYPE.FOOD_AND_BEV;
  const res = await axios.post(`${API.GUEST_REQUEST}`, payload, {
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  // printReceipt(res?.data?.data)

  return res.data;
}

export async function listMenuTypes() {
  const res = await axios({
    url: `${API.FOOD_MENU}/menu-types`,
    method: 'get',
    headers: { authorization: `Bearer ${access_token}`, username: appService.getTenantId() },
  });
  return res.data;
}

export async function menuDetails() {
  const res = await axios({
    url: `${API.FOOD_MENU_DETAILS}`,
    method: 'get',
    headers: { authorization: `Bearer ${access_token}`, username: appService.getTenantId() },
  });
  return res.data;
}


export async function listFoods(params) {
  params = { includeHidden: false, ...params };
  if (Object.prototype.hasOwnProperty.call(params, 'foodName')) {
    delete params.menuType;
  }

  const res = await axios({
    url: `${API.FOOD_MENU}?${qs.stringify(params)}`,
    method: 'get',
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  return res.data;
}

export async function getFoodDetails(foodId) {
  const res = await axios({
    url: `${API.FOOD_MENU}/${foodId}`,
    method: 'get',
    headers: {
      authorization: `Bearer ${access_token}`,
      username: appService.getTenantId(),
    },
  });
  return res.data;
}
