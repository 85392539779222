import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Spa.scss";
import { fetchServices } from "../../services/profileServices";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { BiMinus, BiPlus } from "react-icons/bi";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const OurServices = () => {
  const { services, setServices } = useContext(ProfileContext);
  const { t } = useTranslation();
  const translation = t("profile");
  const gTranslation = t("global");

  const [activeIndex, setActiveIndex] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [translatedData, setTranslatedData] = useState();
  const selectedLang = getSelectedLanguage();

  const { pathname } = useParams();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleDropdown = (ind) => {
    if (ind === activeIndex) {
      setActiveIndex("");
    } else {
      setActiveIndex(ind);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await fetchServices(pathname);
      setServices(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const translateTextBatch = async (texts, targetLang) => {
    return translateTextToTargetLanguage({ text: texts, targetLang });
  };

  const translateServices = useCallback(async () => {
    if (!services) return [];

    if (selectedLang === "en") {
      return services.map((data) => ({
        ...data,
        features: getValue(data, "features").join(" - ").trim(),
      }));
    }

    const servicesPromises = services?.map(async (data) => {
      const features = getValue(data, "features")?.join(" - ")?.replace(/&/g, "and");
      const [translatedFeatures] = await Promise.all([
        // translateTextBatch(name, selectedLang),
        translateTextBatch(features, selectedLang),
      ]);

      return {
        ...data,
        features: translatedFeatures,
      };
    });

    const tServices = await Promise.all(servicesPromises);

    return tServices;
  }, [selectedLang, services]);

  useEffect(() => {
    const fetchTranslatedServices = async () => {
      const tFitness = await translateServices();
      setTranslatedData(tFitness);
    };

    fetchTranslatedServices();
  }, [translateServices]);

  const getModifiedPathName = () => {
    if (pathname === "attractions") {
      return getValue(translation, "services.attractions");
    }

    if (pathname === "parking") {
      return getValue(translation, "services.parking");
    }

    if (pathname === "accessibility") {
      return getValue(translation, "services.accessibility");
    }

    if (pathname === "amenities") {
      return getValue(translation, "services.amenities");
    }

    return capitalizeFirstLetter(pathname);
  };
  return (
    <section
      style={{
        maxWidth: "400px",
        margin: "auto",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha   "
    >
      <h1
        style={{ fontSize: "25px", fontWeight: 600 }}
        className="mx-3 mt-3 mb-2"
      >
        {getModifiedPathName()}
      </h1>
      {pathname === "amenities" && (
        <span className="mx-3" style={{ fontSize: "14px", fontWeight: 700 }}>
          {getValue(translation, "atThisHotel")}
        </span>
      )}
      {isLoading ? (
        <div className=" d-flex justify-content-center mt-5">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      ) : (
        <>
          {services?.length === 0 ? (
            <div className=" d-flex justify-content-center mt-5">
              {getValue(gTranslation, "noDataFound")}
            </div>
          ) : (
            <article className="  mt-4 px-3 ">
              {translatedData?.map((item, index) => (
                <div key={item?._id} className=" all_services   px-3">
                  <div
                    onClick={() => {
                      handleDropdown(index);
                    }}
                    className="d-flex justify-content-between align-items-center my-3"
                  >
                    <h4 style={{ fontWeight: 400 }} className=" cursor_pointer">
                      {item?.name}
                    </h4>
                    {activeIndex === index ? (
                      <BiMinus style={{ color: "#6d6767", fontSize: "20px" }} />
                    ) : (
                      <BiPlus style={{ color: "#6d6767", fontSize: "20px" }} />
                    )}
                  </div>

                  {activeIndex === index &&
                    item?.features?.split("-")?.map((item, index) => (
                      <h4
                        key={index}
                        style={{
                          color: "#8f8f8f",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        className={`my-2${
                          activeIndex === index ? " mh-100" : ""
                        }`}
                      >
                        {item}
                      </h4>
                    ))}
                </div>
              ))}
            </article>
          )}
        </>
      )}
    </section>
  );
};

export default OurServices;
