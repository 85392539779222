import React, { useState, useEffect } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

import API_CONSTANT from "../../constants/api";
import AuthHeader from "./authHeader";
import {
  saveAccessToken,
  saveUser,
  logoutUser,
  saveOrgDetails,
  setFirstLogin,
  getFirstLogin,
} from "../../utils/sessionManager";
import { DynamicRoutes } from "../../constants/routes";
import { appService } from "../../services/appService";
import { getValue } from "../../utils/object";
import GreetingCard from "./GreetingCard";
import PoweredBy from "../partials/PoweredBy";

export default function Login() {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const translation = t("login");

  const data = appService.getTenantData();


  const [formData, setFormData] = useState({ lastName: "", roomNumber: "" });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFirstLogin = () => {
    const firstLogin = getFirstLogin();
    if (firstLogin) setFirstLogin("No");
    else setFirstLogin("Yes");
  };

  const loginRequest = (payload) => {
    axios
      .post(`${API_CONSTANT.GUEST_LOGIN}`, payload, {
        headers: {
          username: appService.getTenantId(),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.userInfo && data.accessToken) {
          handleFirstLogin();
          if (data.org_details) saveOrgDetails(data.org_details);
          saveUser(data.userInfo);
          saveAccessToken(data.accessToken);
          window.location.replace(DynamicRoutes.ClientRoot);
          return;
        }
        addToast("Sorry, Did not match any bookings!", {
          autoDismiss: true,
          appearance: "error",
        });
      })
      .catch((err) => {
        console.log("ERROR:", err);
        addToast("Please type correct room number!", {
          autoDismiss: true,
          appearance: "error",
        });
      });
  };

  useEffect(() => {
    logoutUser();
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    loginRequest(JSON.stringify(formData));
  };

  return (
    <div>
      <AuthHeader />

      <section
        className=""
        style={{
          fontFamily: "Inter",
        }}
      >
        <div className="container">
          <GreetingCard isLogin logo={getValue(data, 'imageUrl')} />
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="login_box">
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onSubmit={handleFormSubmit}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 25,
                    }}
                  >
                    <div className="sin_input login_input">
                      <p>{getValue(translation, "form.lastName")}</p>
                      <label />
                      <input
                        style={{ color: "#222" }}
                        onChange={handleInputChange}
                        value={formData.lastName}
                        name="lastName"
                        type="text"
                        placeholder={getValue(
                          translation,
                          "form.lastNamePlaceHolder"
                        )}
                        required
                      />
                    </div>
                    <div className="sin_input login_input">
                      <p>{getValue(translation, "form.roomNumber")}</p>
                      <label />
                      <input
                        style={{ color: "#222" }}
                        onChange={handleInputChange}
                        value={formData.roomNumber}
                        name="roomNumber"
                        type="text"
                        placeholder={getValue(
                          translation,
                          "form.roomNumberPlaceHolder"
                        )}
                        required
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "100px",
                    }}
                    className="ha_button login_button"
                  >
                    <input
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                        height: "50px",
                        backgroundColor: "black",
                      }}
                      type="submit"
                      value={getValue(translation, "loginBtn")}
                      readOnly
                    />
                  </div>
                  <div
                    style={{
                      margin: "10px 0px",
                    }}
                  >
                    <PoweredBy />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
