import "./requestStatusDetails.scss";

import moment from "moment";
import { Button, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { GUEST_REQ_TYPE } from "../../constants";
import { appService } from "../../services/appService";
import { getOrgDetails } from "../../utils/sessionManager";
import * as service from "../../services/internalServiceService";
import { ConfirmationModalUiConfig } from "../InternalServices/BookInternalService";
import {
  CompletedRequest,
  ActiveRequest,
  CompletedStatuses,
  ActiveStatuses,
} from "./myStatus";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";

export const RequestStatusDetailsPage = () => {
  const { state: { item = {} } = {} } = useLocation();
  const { t } = useTranslation();
  const translation = t("requestDetails");
  const statusTranslation = t("status")

  const totalQuantity = (item.orders || []).reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );
  const orgDetails = useMemo(() => getOrgDetails() || {}, []);

  const { addToast } = useToasts();
  const [cancelling, setCancelling] = useState(false);

  return (
    <section className="mtabs_items_area" id="page__request-status-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
            <div id="mtabs">
              <div className="all_tab_items">
                <div>
                  <div className="row">
                    <div className="col-12 pt-2">
                      {/* Status card */}
                      {CompletedStatuses.includes(item.status) ? (
                        <CompletedRequest item={item} translation={statusTranslation} />
                      ) : (
                        <ActiveRequest item={item} translation={statusTranslation} />
                      )}

                      {/* Housekeeping & front office */}
                      {[
                        GUEST_REQ_TYPE.FRONT_OFFICE,
                        GUEST_REQ_TYPE.HOUSE_KEEPING,
                      ].includes(item.type) && (
                        <div className="guest-status-card">
                          <h4>{item.requestType}</h4>
                          <p className="mt-2 color-black">{item.details}</p>
                        </div>
                      )}

                      {/* Internal Services */}
                      {item.type === GUEST_REQ_TYPE.INTERNAL_SERVICE && (
                        <>
                          <div className="guest-status-card rounded-bottom mb-1">
                            <h4 className="text-spaced-large text-uppercase mb-3">
                              {item.internalServiceId.title}
                            </h4>
                            <div className="d-flex flex-column flex-lg-row justify-content-around">
                              {ConfirmationModalUiConfig.map(
                                (config, i) =>
                                  !!config.value(item) && (
                                    <p
                                      className="text-spaced-large mb-3"
                                      key={i}
                                    >
                                      {config.title === "Guests" ? (
                                        <>
                                          {getValue(translation, "guests")} x{" "}
                                          {config.value(item)}
                                        </>
                                      ) : (
                                        <>
                                          {config.title} : {config.value(item)}
                                        </>
                                      )}
                                    </p>
                                  )
                              )}
                            </div>
                          </div>
                          <div className="guest-status-card rounded-top d-flex align-items-center justify-content-between mt-1 color-black font-small text-bold">
                            <span className="ls-1dot5">
                              {getValue(translation, "total")}
                            </span>
                            <span>
                              {orgDetails.currency}{" "}
                              {item.price?.toLocaleString()}
                            </span>
                          </div>
                        </>
                      )}

                      {/* F&B Orders */}
                      {item.type === GUEST_REQ_TYPE.FOOD_AND_BEV && (
                        <>
                          {/* Order items */}
                          <div className="guest-status-card rounded-bottom">
                            {item.orders.map((order, i) => (
                              <div className="mb-4 color-black" key={i}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="col-6 pl-0 ls-1dot5 font-small">
                                    {order.orderName.toUpperCase()}
                                  </span>
                                  <span className="col-3 text-right font-small">
                                    x {order.quantity}
                                  </span>
                                  <span className="col-3 text-right pr-0 font-small">
                                    {orgDetails.currency}{" "}
                                    {(
                                      order.quantity * order.price
                                    )?.toLocaleString()}
                                  </span>
                                </div>
                                {order.customization && (
                                  <div className="mt-1 col-12 p-0 instruction">
                                    {getValue(translation, "customization")}:{" "}
                                    {order.customization}
                                  </div>
                                )}
                                {order.instruction && (
                                  <div className="mt-1 col-12 p-0 instruction">
                                    {getValue(translation, "instruction")}:{" "}
                                    {order.instruction}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          {/* Totals */}
                          {/* <div className="guest-status-card rounded-top rounded-bottom color-black mt-1 mb-1">
                            <div className="align-items-center justify-content-between d-flex mb-2 font-small">
                              <div className="ls-1dot5">
                                {getValue(translation, "subTotal")}
                              </div>
                              <div>
                                {orgDetails.currency}{" "}
                                {(
                                  item.totalAmount -
                                  item.serviceCharge -
                                  item.vat
                                )?.toLocaleString()}
                              </div>
                            </div>
                            <div className="align-items-center justify-content-between d-flex mb-2 font-small">
                              <div className="ls-1dot5">
                                {getValue(translation, "serviceCharge")} (
                                {item.serviceChargePercentage}%)
                              </div>
                              <div>
                                {orgDetails.currency}{" "}
                                {item.serviceCharge?.toLocaleString()}
                              </div>
                            </div>
                            <div className="align-items-center justify-content-between d-flex mb-2 font-small">
                              <div className="ls-1dot5">
                                {getValue(translation, "vat")} (
                                {item.vatPercentage}%)
                              </div>
                              <div>
                                {orgDetails.currency}{" "}
                                {item.vat?.toLocaleString()}
                              </div>
                            </div>
                          </div> */}
                          <div className="guest-status-card justify-content-between align-items-center d-flex rounded-top color-black mt-1 mb-1 text-bold font-small">
                            <div className="ls-1dot5">
                              {getValue(translation, "total")}
                            </div>
                            <div>
                              {orgDetails.currency}{" "}
                              {item.totalAmount?.toLocaleString()}
                            </div>
                          </div>
                        </>
                      )}

                      {/* Footer card (Delivery time & Request Created) */}
                      <div className="guest-status-card d-flex align-items-center justify-content-between">
                        {item.completionBy && (
                          <div className="ml-md-4">
                            <p className="color-black mb-2">
                              <strong>
                                {getValue(translation, "deliveryTime")}
                              </strong>
                            </p>
                            <p className="color-black">{item.completionBy}</p>
                          </div>
                        )}
                        <div className="mr-md-4">
                          <p className="color-black mb-2">
                            <strong>
                              {getValue(translation, "requestCreated")}
                            </strong>
                          </p>
                          <p className="color-black">
                            {moment(item.createdAt).format(
                              "Do MMM YYYY, hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>

                      {/* Cancel booking - internal service */}
                      {ActiveStatuses.includes(item.status) &&
                        item.type === GUEST_REQ_TYPE.INTERNAL_SERVICE && (
                          <div className="d-flex justify-content-center mt-3">
                            <Button
                              className="btn-danger w-100 mt-3"
                              disabled={cancelling}
                              onClick={() => {
                                setCancelling(true);
                                service
                                  .cancelBooking(item._id)
                                  .then(() => {
                                    addToast(
                                      getValue(translation, "bookingCanceled"),
                                      {
                                        autoDismiss: true,
                                        appearance: "success",
                                      }
                                    );
                                    appService.goBack();
                                  })
                                  .catch(() => {
                                    addToast(
                                      getValue(
                                        translation,
                                        "bookingCancelFailed"
                                      ),
                                      {
                                        autoDismiss: true,
                                        appearance: "error",
                                      }
                                    );
                                  })
                                  .finally(() => {
                                    setCancelling(false);
                                  });
                              }}
                            >
                              {cancelling ? (
                                <Spinner size="sm" />
                              ) : (
                                getValue(translation, "cancelBooking")
                              )}
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequestStatusDetailsPage;
