module.exports = {
  MENU_CATEGORY: {
    ALL_DAY: 'all day',
    BEVERAGES: 'beverage',
    BREAKFAST: 'breakfast',
    DESSERT: 'dessert',
    DINNER: 'dinner',
    LUNCH: 'lunch',
    OTHER: 'other',
  },
  GUEST_REQ_TYPE: {
    FOOD_AND_BEV: 'foodandbev',
    FRONT_OFFICE: 'front_office',
    HOUSE_KEEPING: 'housekeeping',
    INTERNAL_SERVICE: 'internal_service',
  },
  GUEST_REQ_STATUS: {
    NOT_ASSIGNED: 'Not Assigned',
    IN_PROGRESS: 'In-Progress',
    COMPLETED: 'Completed',
    BOOKED: 'Booked',
    CANCELED: 'Canceled',
  },
  SERVICE_TYPE: {
    CLEANING: 'Cleaning Request',
    FOOD_BEV: 'Food and Beverage',
    LAUNDRY: 'Laundry Service',
    LUGGAGE: 'Luggage Pick',
    REPORT_ISSUE: 'Report Issues',
    ROOM_SERVICE: 'Room Service',
    WAKEUP_CALL: 'Wakeup Call',
    REQUEST_ITEM: 'Request an Item',
  },
  INTERNAL_SERVICE_PRICE_TYPE: {
    Fixed: 'fixed',
    PerPerson: 'perPerson',
  },
};
