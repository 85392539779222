import React, { useState } from "react";
import YellowStar from "../../assets/images/icon/yellowstar.svg";
import GrayStar from "../../assets/images/icon/graystar.svg";
const Rating = ({ rating, setRating }) => {
  const handleRatingClick = (value) => {
    setRating(value);
  };
  return (
    <div>
      {Array.from({ length: 5 }, (_, index) => {
        const emoji =
          index < rating ? (
            <img src={YellowStar} alt="YellowStar" />
          ) : (
            <img src={GrayStar} alt="GrayStar" />
          );

        return (
          <span
            className="mr-2"
            key={index}
            onClick={() => handleRatingClick(index + 1)}
          >
            {emoji}
          </span>
        );
      })}
    </div>
  );
};

export default Rating;
