import React from "react";
// import AvendiLogo from "../../assets/images/avendi_logo.png";
import AvendiLogo from "../../assets/images/logo_1.svg";

const PoweredBy = () => {
  return (
    <div
      style={{
        width: "full",
        // display: "flex",
        // alignItems: "end",
        textAlign: "center",
      }}
    >
      <span
        style={{
          fontSize: "12px",
          color: "#ABABAB",
          letterSpacing: "-0.3px",
        }}
      >
        Powered by{" "}
      </span>
      <img
        style={{
          height: "12px",
          marginTop: "-3px",
          filter: "grayscale(100%)",
        }}
        src={AvendiLogo}
        alt="avendi logo"
      />
    </div>
  );
};

export default PoweredBy;
