import axios from "axios";
import API from "../constants/api";
import { appService } from "./appService";
import { getAccessToken } from "../utils/sessionManager";

const access_token = getAccessToken();

export async function profile(setHotelDetails) {
  const res = await axios({
    url: `${API.Profile}`,
    method: "get",
    headers: {
      username: appService.getTenantId(),
    },
  });
  setHotelDetails(res.data);
  return res.data;
}

export const fetchProfileData = async () => {
  try {
    const res = await axios({
      url: `${API.Profile}`,
      method: "get",
      headers: {
        username: appService.getTenantId(),
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};

export const fetchOurProperties = async () => {
  try {
    const res = await axios({
      url: `${API.OurProperties}`,
      method: "get",
      headers: {
        authorization: `Bearer ${access_token}`,
        username: appService.getTenantId(),
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};

export const fetchDineAndDrinks = async () => {
  try {
    const res = await axios({
      url: `${API.DinAndDrinks}`,
      method: "get",
      headers: {
        authorization: `Bearer ${access_token}`,
        username: appService.getTenantId(),
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};

export const fetchSpa = async () => {
  try {
    const res = await axios({
      url: `${API.Spa}`,
      method: "get",
      headers: {
        authorization: `Bearer ${access_token}`,
        username: appService.getTenantId(),
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};

export const fetchFitness = async () => {
  try {
    const res = await axios({
      url: `${API.Fitness}`,
      method: "get",
      headers: {
        authorization: `Bearer ${access_token}`,
        username: appService.getTenantId(),
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};

export const fetchServices = async (pathname) => {
  try {
    const res = await axios({
      url: `${API.ServicesType}?attractionType=${pathname}`,
      method: "get",
      headers: {
        authorization: `Bearer ${access_token}`,
        username: appService.getTenantId(),
      },
    });
    return res.data;
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};
