import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getRecentTenants } from "./services/localStorageService";

import { Routes } from "./constants/routes";
import { appService } from "./services/appService";

const TenantCard = ({ data }) => {
  const { _id, hotelName, hotelBranch, imageUrl, username } = data;

  const goToTenantLoginPage = (e) => {
    e.preventDefault();
    appService.goTo(Routes.Home, { tenantId: username });
  };

  return (
    <li
      key={_id}
      className="recent-tenant-card my-3 d-flex flex-column align-items-center"
      onClick={goToTenantLoginPage}
      role="presentation"
    >
      <img src={imageUrl} alt={hotelName} height="100px" />
      <h4 className="text-center mt-3 text-avendi-purple">
        <Link
          to="#signup"
          onClick={goToTenantLoginPage}
          className="text-avendi-gray"
        >
          {hotelName} - {hotelBranch}
        </Link>
      </h4>
    </li>
  );
};

const NotFoundPage = () => {
  const [recentTenants, setRecentTenants] = useState([]);

  useEffect(() => {
    appService.resetAppContext();
    const tenantsData = getRecentTenants();
    setRecentTenants(tenantsData);
  }, []);

  return (
    <div className="container">
      <div id="not-found-screen" className="row">
        <div className="col-sm-8 logo_spinner_container">
          <h1 id="splash_logo" className="mb-5">
            Avendi
          </h1>
          <div>
            <p className="text-center pb-4">
              {
                "Oops! Looks like the page or hotel you're looking for doesn't exist."
              }
            </p>

            {!!recentTenants.length && (
              <>
                <p className="text-center mt-4">
                  {"Maybe you're looking to access your recent hotels?"}
                </p>
                <ul className="recents mt-4 d-flex flex-wrap justify-content-around">
                  {recentTenants.map((t) => (
                    <TenantCard data={t} key={t._id} />
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TenantCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hotelName: PropTypes.string.isRequired,
    hotelBranch: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotFoundPage;
export { NotFoundPage };
