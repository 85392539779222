import "./sentry"
import React from 'react';
import AdapterMoment from '@mui/lab/AdapterMoment';
import ReactGA from 'react-ga4';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ReactDOM from 'react-dom';
import App from './modules/App';

import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/css/font-awesome-4.7.0.min.css';

import './assets/styles/scss/style.scss';
import './assets/styles/scss/helpers.scss';
import './assets/styles/scss/responsive.scss';
import './assets/styles/scss/custom.scss';
import Hotjar from './modules/analytics/Hotjar';
import LoadingScreen from './LoadingScreen';
import "./i18n"

if (process.env.REACT_APP_ENV === 'production') {
  ReactGA.initialize('G-RQEZGDPKGP');
}

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<LoadingScreen />}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Hotjar />
      <App />
    </LocalizationProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Deploying new ui changes