import React, { createContext, useReducer } from 'react';

import hotelServiceReduce from '../reducers/hotelServiceReducer';
import * as Service from '../services/hotelService';
import ACTIONS from '../actions/hotelService';

const initialState = {
  list: [],
  requestStatus: [],
};

export const HotelServiceContext = createContext(initialState);

export const HotelServiceContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(hotelServiceReduce, initialState);

  function submitGuestRequest(payload) {
    return Service.submitGuestRequest(payload);
  }

  
  function submitGuestFeedback(payload) {
    return Service.submitGuestFeedback(payload);
  }
  async function myRequestStatus() {
    const data = await Service.listMyRequestStatus();
    if (data) {
      dispatch({ type: ACTIONS.GET_REQUEST_STAT, data: data });
    }
    return data;
  }

  return (
    <HotelServiceContext.Provider
      value={{
        list: state.list,
        requestStatus: state.requestStatus,
        dispatch,
        myRequestStatus,
        submitGuestRequest,
        submitGuestFeedback
      }}
    >
      {children}
    </HotelServiceContext.Provider>
  );
};
