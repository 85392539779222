import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useContext, useEffect, useCallback } from "react";

import { FoodItem } from "./FoodItem";
import MenuCategory from "./menuCategory";
import LoadMore from "../global/LoadMore";
import { FoodContext } from "../../contexts/FoodContext";

import { useDebounceValue } from "../../utils/hooks";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";
import { appService } from "../../services/appService";

const CATEGORY_QUERY_KEY = "category";

export default function Index() {
  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get(CATEGORY_QUERY_KEY);
  const selectedLang = getSelectedLanguage();

  const { t } = useTranslation();
  const translation = t("foodMenu");
  const tenantData = appService.getTenantData();

  const { listFoods, menuDetails } = useContext(FoodContext);
  const [menuCategory, setMenuCategory] = useState(category || "");
  const [menuListDetails, setMenuListDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFoodCount, setTotalFoodCount] = useState(0);
  const [size, setSize] = useState(1); // Page limit
  const [foodList, setFoodList] = useState([]);
  const [searchFoodList, setSearchFoodList] = useState([]);
  const [menuTypesList, setMenuTypesList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounceValue(searchText, 500);

  const [translatedCategoryData, setTranslatedCategoryData] = useState([]);
  const [translatedFoodList, setTranslatedFoodList] = useState([]);
  const [titleNote, setTitleNote] = useState("");

  const fetchFoodList = () => {
    if (!menuCategory) return;

    const params = { menuType: menuCategory };
    if (currentPage) params.page = currentPage;
    listFoods(params)
      .then((res) => {
        const { size: _size, total } = res.meta;
        const _location = {
          ...location,
          search: `?${CATEGORY_QUERY_KEY}=${encodeURIComponent(
            params.menuType
          )}`,
        };
        history.replace(_location);

        setFoodList(res.data);
        setTotalFoodCount(total);
        setSize(_size);
      })
      .catch(() => {
        addToast("Internal server error!", {
          autoDismiss: true,
          appearance: "error",
        });
      });
  };

  // const fetchFoodMenuTypes = () => {
  //   listMenuTypes()
  //     .then((res) => {
  //       if (!Array.isArray(res) || !res.length) {
  //         return;
  //       }
  //       const sortedMenuCategories = res.sort((a, b) => {
  //         if (!clientSettings.menuCategoryPriorities) return null;

  //         const { menuCategoryPriorities } = clientSettings;
  //         const aPriority =
  //           menuCategoryPriorities[a.toLowerCase()] || res.indexOf(a);
  //         const bPriority =
  //           menuCategoryPriorities[b.toLowerCase()] || res.indexOf(b);

  //         return aPriority - bPriority;
  //       });

  //       const menuType = category || sortedMenuCategories[0];
  //       const index = sortedMenuCategories.indexOf(menuType);
  //       setTimeout(() =>
  //         document
  //           .getElementById(`foodMenuCategoryItem_${index}`)
  //           ?.scrollIntoView(false)
  //       );

  //       setMenuCategory(menuType);
  //       setMenuTypesList(sortedMenuCategories);
  //     })
  //     .catch((err) => {});
  // };

  const fetchMenuDetails = () => {
    menuDetails()
      .then((res) => {
        setMenuListDetails(res.data);
        const sortedMenuItems = [...res.data].sort(
          (a, b) => a.position - b.position
        );
        const menuType = category || sortedMenuItems[0]?.foodAndBevCategoryName;
        setMenuCategory(menuType);
        setMenuTypesList(sortedMenuItems);
      })
      .catch((err) => {});
  };

  const handleMenuCategoryClick = (e, item) => {
    setFoodList([]);
    e.preventDefault();
    setCurrentPage(1);
    setMenuCategory(item);
  };

  const handleLoadMoreClick = () => {
    const params = {};
    if (menuCategory) params.menuType = menuCategory;
    if (debouncedSearchText && searchText)
      params.foodName = debouncedSearchText;

    const pages = Math.ceil(totalFoodCount / size);
    const nextPage = currentPage + 1;
    params.page = nextPage;
    if (nextPage > pages) {
      return addToast(getValue(translation, "noMoreData"), {
        autoDismiss: true,
        appearance: "info",
      });
    }

    listFoods(params)
      .then((res) => {
        const { size: _size, total, page } = res.meta;
        if (debouncedSearchText && searchText) {
          setSearchFoodList((curr) => [...curr, ...res.data]);
        } else {
          setFoodList([...foodList, ...res.data]);
        }

        setTotalFoodCount(total);
        setSize(_size);
        setCurrentPage(page);
      })
      .catch();
  };

  // useEffect(fetchFoodMenuTypes, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchMenuDetails, []);

  useEffect(fetchFoodList, [menuCategory]);

  useEffect(() => {
    if (!debouncedSearchText) return;

    listFoods({ foodName: debouncedSearchText }).then((res) => {
      setSearchFoodList(res.data);
      setCurrentPage(res.meta.page);
      setTotalFoodCount(res.meta.total);
      setSize(res.meta.size);
    });
  }, [debouncedSearchText]);

  const startTime = menuListDetails
    ?.filter((item) => item?.foodAndBevCategoryName == `${menuCategory}`)
    .map((item) => item?.availability?.start);
  const endTime = menuListDetails
    ?.filter((item) => item?.foodAndBevCategoryName == `${menuCategory}`)
    .map((item) => item?.availability?.end);

  const translateTextBatch = async (texts, targetLang) => {
    return translateTextToTargetLanguage({ text: texts, targetLang });
  };

  const translateCategoryList = useCallback(async () => {
    if (!menuListDetails) return [];
    if (selectedLang === "en") {
      return menuListDetails?.map((item) => {
        const startTime = item?.availability?.start;
        const endTime = item?.availability?.end;

        const servedFrom = startTime
          ? moment(startTime).format(" h:mm a")
          : "--";

        const servedTo = endTime ? moment(endTime).format(" h:mm a") : "--";
        return {
          ...item,
          translatedCategory: item.foodAndBevCategoryName,
          translatedNote: item.notes,
          translatedTitleNote: `Served from ${servedFrom} to ${servedTo}. ${item.notes}`,
        };
      });
    }

    const categories = menuListDetails
      ?.map((food) => food.foodAndBevCategoryName)
      .join(" ** ")
      .replace(/&/g, "and");
    const notes = menuListDetails?.map((food) => food.notes).join(" ** ").replace(/&/g, "and");

    const titleNotes = menuListDetails
      ?.map((food) => {
        const startTime = food?.availability?.start;
        const endTime = food?.availability?.end;

        const servedFrom = startTime
          ? moment(startTime).format(" h:mm a")
          : "--";

        const servedTo = endTime ? moment(endTime).format(" h:mm a") : "--";
        return `Served from ${servedFrom} to ${servedTo}. ${food.notes}`;
      })
      .join(" ** ").replace(/&/g, "and");

    const [translatedCategory, translatedNote, translatedTitleNote] =
      await Promise.all([
        translateTextBatch(categories, selectedLang),
        translateTextBatch(notes, selectedLang),
        translateTextBatch(titleNotes, selectedLang),
      ]);

    const translatedCategoryArray = translatedCategory.split("**");
    const translatedNoteArray = translatedNote.split("**");
    const translatedTitleNoteArray = translatedTitleNote.split("**");

    return menuListDetails?.map((food, index) => ({
      ...food,
      translatedCategory: translatedCategoryArray[index]?.trim(),
      translatedNote: translatedNoteArray[index]?.trim(),
      translatedTitleNote: translatedTitleNoteArray[index]?.trim(),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLang, menuListDetails]);

  useEffect(() => {
    const fetchTranslatedCategoryList = async () => {
      const tFoodCategory = await translateCategoryList();
      setTranslatedCategoryData(tFoodCategory);
    };

    fetchTranslatedCategoryList();
  }, [translateCategoryList]);

  useEffect(() => {
    const category = translatedCategoryData?.find(
      (data) => getValue(data, "foodAndBevCategoryName") === menuCategory
    );
    setTitleNote(getValue(category, "translatedTitleNote"));
  }, [menuCategory, translatedCategoryData]);

  const translateFoodList = useCallback(async () => {
    if (!foodList) return [];
    if (selectedLang === "en") {
      return foodList.map((food) => ({
        ...food,
        notes: tenantData?.clientSettings?.guestAppFoodMenuNote?.(
          food.menuType
        ),
      }));
    }

    const foodListPromise = foodList.map(async (food) => {
      const notes = tenantData?.clientSettings?.guestAppFoodMenuNote?.(
        food.menuType
      );
      const [
        // translatedNames,
        translatedDescriptions,
        // translatedCategories,
        translatedNotes,
      ] = await Promise.all([
        // translateTextBatch(names, selectedLang),
        translateTextBatch(
          getValue(food, "description").replace(/&/g, "and"),
          selectedLang
        ),
        // translateTextBatch(getValue(food, "categories"), selectedLang),
        translateTextBatch(notes, selectedLang),
      ]);
      return {
        ...food,
        description: translatedDescriptions,
        // categories: translatedCategories,
        notes: translatedNotes,
      };
    });

    const tFoodList = await Promise.all(foodListPromise);

    return tFoodList;
  }, [selectedLang, foodList, tenantData]);

  useEffect(() => {
    const fetchTranslatedFoodList = async () => {
      const tFoodList = await translateFoodList();
      setTranslatedFoodList(tFoodList);
    };

    fetchTranslatedFoodList();
  }, [translateFoodList]);

  return (
    <>
      <section className="mtabs_items_area overflow-hidden">
        <div className="container">
          <div className="row">
            {/* Search Box */}

            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 mb-3">
              {/* <div className="search_box">
                <Input
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                <button
                  type="button"
                  onClick={() => {
                    setSearchText("");
                    setSearchFoodList([]);
                    setCurrentPage(1);
                    const _cat = menuCategory;
                    setMenuCategory("");
                    setTimeout(() => setMenuCategory(_cat));
                  }}
                  className="btn btn-transparent icon-btn"
                >
                  <img src={CrossIcon} className="text-avendi-gray" />
                </button>
              </div> */}
            </div>

            {/* Items With Search */}
            {!!searchText && !!debouncedSearchText && (
              <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
                <div className="all_tabs_items">
                  <div className="new_page_items_box">
                    {searchFoodList && searchFoodList.length > 0
                      ? searchFoodList.map((food) => (
                          <FoodItem
                            startTimes={startTime}
                            endTimes={endTime}
                            food={food}
                            key={food._id}
                            showTiming={true}
                          />
                        ))
                      : getValue(translation, "noFoodItemFound")}
                  </div>
                </div>
                <LoadMore handleLoadMoreClick={handleLoadMoreClick} />
              </div>
            )}

            {/* Items With Categories */}
            {!searchText && !debouncedSearchText && (
              <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
                <div id="mtabs">
                  <div className="tabs_menu new_page_menu">
                    <ul
                      id="foodMenuCategoryItemsContainer"
                      className="scrollbar-hidden scroll-x flex-nowrap"
                    >
                      {menuTypesList && menuTypesList.length > 0 && (
                        <MenuCategory
                          list={menuTypesList}
                          menuListDetails={translatedCategoryData}
                          currentMenuType={menuCategory}
                          handleMenuCategoryClick={handleMenuCategoryClick}
                        />
                      )}
                    </ul>
                  </div>
                  <div style={{ fontSize: "12px" }} className="text-dark mt-3 ">
                    {titleNote}
                  </div>
                  <div className="all_tabs_items">
                    <div className="new_page_tabs_area pt-1">
                      <div className="">
                        {(translatedFoodList || []).map((food) => (
                          <FoodItem
                            startTimes={startTime}
                            endTimes={endTime}
                            food={food}
                            key={food._id}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {totalFoodCount > 10 ? (
                  <LoadMore handleLoadMoreClick={handleLoadMoreClick} />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
