import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Spa.scss";
import { fetchFitness } from "../../services/profileServices";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const Fitness = () => {
  const { fitness, setFitness } = useContext(ProfileContext);
  const { t } = useTranslation();
  const translation = t("profile");
  const gTranslation = t("global");
  const selectedLang = getSelectedLanguage();

  const [isLoading, setIsLoading] = useState(false);
  const [translatedData, setTranslatedData] = useState();

  const fetchData = useCallback(async () => {
    try {
      if (!fitness) {
        setIsLoading(true);
        const data = await fetchFitness();
        setFitness(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const translateTextBatch = async (texts, targetLang) => {
    return translateTextToTargetLanguage({ text: texts, targetLang });
  };

  const translateFitness = useCallback(async () => {
    if (!fitness) return [];

    if (selectedLang === "en") {
      return fitness.map((data) => ({
        ...data,
        features: getValue(data, "features").join(" - ").trim(),
      }));
    }

    const fitnessPromises = fitness?.map(async (data) => {
      const features = getValue(data, "features")?.join(" - ")?.replace(/&/g, "and");
      const [translatedFeatures] = await Promise.all([
        // translateTextBatch(name, selectedLang),
        translateTextBatch(features, selectedLang),
      ]);

      return {
        ...data,
        features: translatedFeatures,
      };
    });

    const tFitness = await Promise.all(fitnessPromises);

    return tFitness;
  }, [selectedLang, fitness]);

  useEffect(() => {
    const fetchTranslatedFitness = async () => {
      const tFitness = await translateFitness();
      setTranslatedData(tFitness);
    };

    fetchTranslatedFitness();
  }, [translateFitness]);

  return (
    <section
      style={{
        maxWidth: "400px",
        margin: "auto",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha   "
    >
      <h1
        style={{ fontSize: "25px", fontWeight: 600 }}
        className="mx-3 mt-3 mb-2"
      >
        {getValue(translation, "services.fitness")}
      </h1>
      <span className="mx-3" style={{ fontSize: "14px", fontWeight: 700 }}>
        {" "}
        {getValue(translation, "atThisHotel")}
      </span>
      {isLoading ? (
        <div className=" d-flex justify-content-center mt-5">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      ) : (
        <>
          {fitness?.length < 0 ? (
            <div className=" d-flex justify-content-center mt-5">
              {getValue(gTranslation, "noDataFound")}
            </div>
          ) : (
            <article className="  mt-4 px-3 ">
              {translatedData?.map((item, index) => (
                <div
                  key={item?._id}
                  className="mb-3 all_properties cursor_pointer mx-3 p-3"
                >
                  <h4 className="my-2 ">{item?.name}</h4>

                  <span
                    className="text-uppercase"
                    style={{
                      fontSize: "14px",
                      letterSpacing: "1.2px",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {getValue(gTranslation, "features")}
                  </span>

                  {getValue(item, "features") ? (
                    item.features?.split("-").map((item, index) => (
                      <h4
                        key={index}
                        style={{
                          color: "#8f8f8f",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        className="my-1"
                      >
                        {item}
                      </h4>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </article>
          )}
        </>
      )}
    </section>
  );
};

export default Fitness;
