/* eslint-disable import/no-anonymous-default-export */
import ACTIONS from '../actions/hotelService';

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_REQUEST:
      return {
        ...state,
      };

    case ACTIONS.GET_REQUEST_STAT:
      return {
        ...state,
        requestStatus: action.data,
      };

    default:
      return state;
  }
};
