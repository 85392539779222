import "./BookInternalService.scss";

import moment from "moment";
import { TextField } from "@mui/material";
import { Button, Spinner } from "reactstrap";
import MaterialTimePicker from "@mui/lab/MobileTimePicker";
import { Link, useParams, useLocation } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import NotFound from "../../assets/images/img/no-image-available.png";

import { DynamicRoutes } from "../../constants/routes";
import { appService } from "../../services/appService";
import { INTERNAL_SERVICE_PRICE_TYPE } from "../../constants";
import * as service from "../../services/internalServiceService";
import { getOrgDetails, getUser } from "../../utils/sessionManager";

import { ReactComponent as TeamMemberIcon } from "../../assets/images/icon/team-member.svg";
import { ReactComponent as Calendar } from "../../assets/images/icon/booking.svg";
import { ReactComponent as TimeIcon } from "../../assets/images/icon/analytics.svg";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const CONFIRM = "#confirm";
const TIME_FORMAT = "h:mm A";
const ISO_FORMAT = "YYYY-MM-DD";
const BookingCountOptions = Array.from(Array(10), (v, i) => i + 1);
export const ConfirmationModalUiConfig = Object.freeze([
  {
    title: "Date",
    icon: <Calendar />,
    value: (values) => moment(values?.visitDate).format("ddd, Do MMM, YYYY"),
  },
  {
    title: "Guests",
    icon: <TeamMemberIcon />,
    value: (values) => values?.noOfPeople,
  },
  {
    title: "Time",
    icon: <TimeIcon />,
    value: (values) => values?.selectedDuration?.sets[0]?.selectedTime,
  },
]);

/**
 * Book an internal service.
 */
const BookInternalService = () => {
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const translation = t("internalServiceDetails");
  const gTranslation = t("global");

  const selectedLang = getSelectedLanguage();

  const [serviceItem, setServiceItem] = useState({});
  const [translatedServiceItem, setTranslatedServiceItem] = useState({});
  const [bookingCount, setBookingCount] = useState(1);
  const [specialRequest, setSpecialRequest] = useState("");
  const [visitDate, setVisitDate] = useState(moment().format(ISO_FORMAT));
  const [visitTime, setVisitTime] = useState("");

  const [duration, setDuration] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [day, setDay] = useState(moment().format("dddd"));
  const [availableTimes, setAvailableTimes] = useState([]);
  const [unitPrice, setUnitPrice] = useState(0);

  const orgDetails = getOrgDetails() || {};
  const currentUser = getUser();

  const [confirming, setConfirming] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getTotalAmount = () =>
    serviceItem.priceType === INTERNAL_SERVICE_PRICE_TYPE.PerPerson
      ? bookingCount * unitPrice
      : unitPrice;

  const getTotalAmounts = () => {
    const subTotal = getTotalAmount();
    const serviceCharge = ((orgDetails.serviceCharge || 0) / 100) * subTotal;
    const totalAfterServiceCharge = subTotal + serviceCharge;
    const valueAddedTax =
      ((orgDetails.vat || 0) / 100) * totalAfterServiceCharge;
    const grandTotal = subTotal + serviceCharge + valueAddedTax;

    return {
      subTotal,
      serviceCharge,
      totalAfterServiceCharge,
      valueAddedTax,
      grandTotal,
      scPercentage: orgDetails.serviceCharge || 0,
      vatPercentage: orgDetails.vat || 0,
    };
  };

  const fetchServiceItem = useCallback(async () => {
    const { data = {}, error } = await service.getItemById(params?.id);
    if (error) {
      alert("Error when fetching item.");
      return;
    }
    setServiceItem(data);
    setDuration("");
  }, [params?.id]);
  useEffect(() => {
    if (location.hash !== CONFIRM) {
      setConfirming(false);
      return;
    }

    setConfirming(true);
  }, [location]);

  useEffect(() => {
    const durationItem =
      serviceItem.duration?.find((d) => d.time === duration) || {};
    const setItem = durationItem.sets?.find((s) => s.day === day) || {};
    const _availableTimes =
      setItem._availableTime || setItem.availableTime || [];

    const validSlots = service.getOnlyValidTimeSlotInterval(
      visitDate,
      _availableTimes
    );

    setUnitPrice(durationItem.price || serviceItem.price);
    setAvailableTimes(validSlots);
    setTimeSlot(validSlots[0] || "");
  }, [serviceItem, day, duration]);

  useEffect(() => {
    fetchServiceItem();
  }, [fetchServiceItem]);

  useEffect(() => {
    const translateServiceItem = async () => {
      if (selectedLang === "en") {
        setTranslatedServiceItem(serviceItem);
        return;
      }

      const [
        // translatedName,
        translatedDescription,
      ] = await Promise.all([
        // translateTextToTargetLanguage({
        //   text: serviceItem.name,
        //   targetLang: selectedLang,
        // }),
        translateTextToTargetLanguage({
          text: getValue(serviceItem, "description", "").replace(/&/g, "and"),
          targetLang: selectedLang,
        }),
      ]);

      setTranslatedServiceItem((prev) => ({
        ...prev,
        name: getValue(serviceItem, "name"),
        description: translatedDescription,
      }));
    };
    translateServiceItem();
  }, [selectedLang, serviceItem]);

  const submitForm = async (e, isConfirmed = false) => {
    e.preventDefault();
    const totals = getTotalAmounts();
    const visitDateTime =
      serviceItem.isSetsAvailable || !visitTime
        ? visitDate
        : moment(visitDate, ISO_FORMAT).set({
            hour: visitTime.get("hour"),
            minute: visitTime.get("minute"),
            second: visitTime.get("second"),
          });
    const values = {
      visitDate: visitDateTime,
      noOfPeople: bookingCount,
      additionalRequest: specialRequest,
      internalServiceId: params?.id,
      price: totals.grandTotal,
      guestId: currentUser?.id,
      ...(!serviceItem.isSetsAvailable
        ? {}
        : {
            selectedDuration: {
              day,
              price: unitPrice,
              sets: [
                {
                  time: duration,
                  selectedTime: timeSlot,
                },
              ],
            },
          }),
    };

    if (!isConfirmed) {
      appService.goTo(CONFIRM);
      setConfirming(true);
      setModalValues({
        ...values,
        ...totals,
      });
      return;
    }

    setIsSubmitting(true);
    const { error } = await service.bookItem(values);
    setConfirming(false);
    setIsSubmitting(false);

    if (error) {
      alert("Failed to book the session.");
      return;
    }
    setIsSubmitted(true);
  };

  return (
    <>
      <section
        className={`message_area ${confirming && "background_color"}`}
        id="page__book-internal-service"
      >
        {/* background color can be changed from AppRouter.js  component  */}

        <div className="container">
          <div className="row">
            {!confirming && !isSubmitted && (
              <div className="cover_image">
                <img
                  src={serviceItem?.image ?? NotFound}
                  alt={serviceItem?.name}
                  className="img- mb-4 service_img "
                />
              </div>
            )}
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="">
                {(() => {
                  if (confirming) {
                    return (
                      <div style={{ marginBottom: "40vh" }}>
                        <h5 className="color-black my-4 ml-1">
                          {getValue(translation, "orderConfirmation")}
                        </h5>
                        <div className="avendi-card order_confirmation rounded-bottom">
                          <p className="text-spaced-large ls-1dot5 title mb-3">
                            {getValue(translatedServiceItem, "name")}
                          </p>
                          {ConfirmationModalUiConfig.map(
                            (config, i) =>
                              !!config.value(modalValues) && (
                                <div
                                  className="text-spaced-large ls-1dot5 mb-3"
                                  key={i}
                                >
                                  {config.title === "Guests" ? (
                                    <div className="d-flex align-items-center">
                                      {" "}
                                      {config.icon}
                                      <span className="ml-2">
                                        {getValue(translation, "guest")} x{" "}
                                        {modalValues.noOfPeople}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="d-flex align-items-center">
                                      {config.icon}
                                      <span className="ml-2">
                                        {config.value(modalValues)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )
                          )}
                          <div
                            style={{ fontWeight: 500 }}
                            className="border-top d-flex align-items-center justify-content-between color-black mt-1 font-small  pt-2"
                          >
                            <span className="ls-1dot5">
                              {getValue(translation, "totalPrice")}
                            </span>
                            <span className="ls-1dot5">
                              {orgDetails?.currency}{" "}
                              {modalValues.grandTotal?.toLocaleString()}
                            </span>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-4">
                          <Button
                            className=" btn-dark confirm_button "
                            disabled={isSubmitting}
                            onClick={(event) => submitForm(event, true)}
                          >
                            {isSubmitting ? (
                              <Spinner size="sm" />
                            ) : (
                              getValue(gTranslation, "confirmBtn")
                            )}
                          </Button>
                        </div>
                      </div>
                    );
                  }

                  if (isSubmitted) {
                    return (
                      <div>
                        <div className="report_message_box">
                          <p>
                            {getValue(translation, "successMsg")}
                            <br />
                            <br />
                            <br />
                            {getValue(translation, "cancellationGuide")}&nbsp;
                            <Link
                              to={`${DynamicRoutes.Dashboard}#my_status`}
                              className="color-inherit"
                            >
                              <u>click here</u>
                            </Link>
                            .
                          </p>

                          <span className="ha_button">
                            <Link to={DynamicRoutes.InternalServices}>
                              {getValue(gTranslation, "goBack")}
                            </Link>
                          </span>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <>
                      <div className=" d-flex justify-content-between"></div>

                      <div className=" title">
                        <h5 className="color-black ">
                          {getValue(translatedServiceItem, "name")}
                        </h5>
                        <span>
                          {orgDetails.currency} {unitPrice?.toLocaleString()}{" "}
                        </span>
                      </div>
                      <p className="description text-left">
                        {getValue(translatedServiceItem, "description")}
                      </p>
                      <form onSubmit={submitForm}>
                        <div className="d-flex ">
                          <div className="mr-2 ha_booking_input dark_label pb-3">
                            <label>
                              {getValue(translation, "numberOfPax")}
                            </label>
                            <select
                              id="bookingCount"
                              name="bookingCount"
                              value={bookingCount}
                              placeholder="Count of people to book this service."
                              onChange={(e) =>
                                setBookingCount(Number(e.target.value))
                              }
                              required
                            >
                              {BookingCountOptions.map((v) => (
                                <option key={v} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </div>
                          {serviceItem?.isSetsAvailable && (
                            <div className="ha_booking_input dark_label pb-3">
                              <label>
                                {getValue(translation, "durationOfService")}
                              </label>
                              <select
                                name="serviceDuration"
                                value={duration}
                                placeholder="Select service duration"
                                onChange={(e) => {
                                  setDuration(e.target.value);
                                }}
                                required
                              >
                                {!duration && (
                                  <option value="">
                                    &nbsp;{" "}
                                    {getValue(translation, "selectDuration")}
                                  </option>
                                )}
                                {serviceItem?.duration.map((d, i) => (
                                  <option key={i} value={d.time}>
                                    {d.time} ({orgDetails.currency}{" "}
                                    {d.price || serviceItem.price}{" "}
                                    {serviceItem.priceType})
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>

                        <div className="ha_booking_input dark_label pb-3">
                          <label>{getValue(gTranslation, "date")}</label>
                          <input
                            type="date"
                            name="visitDate"
                            value={visitDate}
                            min={moment().format(ISO_FORMAT)}
                            placeholder="Choose visit date"
                            onChange={(e) => {
                              setDay(
                                moment(e.target.value, ISO_FORMAT).format(
                                  "dddd"
                                )
                              );
                              setVisitDate(e.target.value);
                            }}
                            required
                          />
                        </div>

                        {/* Request timing with guest when packages/sets aren't available */}
                        {!serviceItem?.isSetsAvailable && (
                          <div className="ha_booking_input dark_label pb-3 full-width-material-input no-fieldset">
                            <label>
                              {getValue(translation, "durationOfService")}
                            </label>
                            <div>
                              <MaterialTimePicker
                                value={visitTime}
                                onChange={setVisitTime}
                                renderInput={(args) => <TextField {...args} />}
                                inputFormat={TIME_FORMAT}
                                displayStaticWrapperAs="mobile"
                              />
                            </div>
                          </div>
                        )}

                        {/* Available timings */}
                        {serviceItem?.isSetsAvailable && (
                          <>
                            {availableTimes?.length > 0 && (
                              <div className="ha_booking_input dark_label pb-3">
                                <label>{getValue(gTranslation, "time")}</label>
                                <div className="service_time">
                                  {availableTimes.map((t, i) => (
                                    <div
                                      key={i}
                                      onClick={(e) => setTimeSlot(t)}
                                      className={
                                        timeSlot === t
                                          ? "time_border_active"
                                          : "time_border_inactive"
                                      }
                                    >
                                      {t.split("-")[0]}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {/* Additional information */}
                        <div className="ha_booking_input dark_label pb-3">
                          <label>
                            {getValue(translation, "additionalInfo")}
                          </label>
                          <textarea
                            name="specialRequest"
                            value={specialRequest}
                            placeholder={getValue(
                              translation,
                              "additionalInfoPlaceholder"
                            )}
                            onChange={(e) => setSpecialRequest(e.target.value)}
                            rows={3}
                          />
                        </div>

                        {/* Total Amount */}
                        <div className="d-flex color-black align-items-center justify-content-between">
                          <label className=" ">
                            {getValue(translation, "totalPrice")}
                          </label>
                          <span
                            style={{ fontWeight: 500 }}
                            className="color-black "
                          >
                            {orgDetails.currency}{" "}
                            {getTotalAmount()?.toLocaleString()}
                          </span>
                        </div>

                        {/* Book now button */}
                        <div className="ha_button mssg_button text-center mt-4">
                          <input
                            className="book_input"
                            type="submit"
                            value={getValue(gTranslation, "bookNow")}
                            disabled={isSubmitting}
                          />
                        </div>
                      </form>
                    </>
                  );
                })()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookInternalService;
export { BookInternalService };
