import axios from 'axios';
import { generatePath } from 'react-router-dom';
import DefaultFavicon from '../assets/images/favicons/_default.ico';

import API from '../constants/api';
import { updateRecentTenants } from './localStorageService';
import { generateDynamicRoutes } from '../constants/routes';
import { settingsPerClient } from '../constants/settingsPerClient';

class AppService {
  /**
   * Set context of history within the service.
   * @param {object} history
   * @returns {AppService}
   */
  setHistory(history) {
    this.history = history;

    return this;
  }

  /**
   * Set context of tenant (tenantId) within the service.
   * @param {string} tenantId
   * @returns {AppService}
   */
  setTenantId(tenantId) {
    if (!this.tenantId && tenantId) {
      this.tenantId = tenantId;
      generateDynamicRoutes(this.tenantId);
    }

    return this;
  }

  /**
   * Set tenant data.
   * @param {object} data
   * @returns {AppService}
   */
  setTenantData(data) {
    if (!this.tenantData && data) {
      this.tenantData = data;
      updateRecentTenants(data);
      document.getElementById('page_favicon').href = data.favicon || data.imageUrl || DefaultFavicon;
      document.title = `${data.hotelName || ''} - ${data.hotelBranch || ''} | Guest - Avendi`;
    }

    return this;
  }

  /**
   * Get the current tenant in the service's context.
   *
   * NOTE: Never cache or save this value in a variable outside a function or component as
   * during module initialization, the value of tenantId will not be set. Always call this
   * function from within a component or function that is executed after it's initialization.
   *
   * @returns {string}
   */
  getTenantId() {
    return this.tenantId;
  }

  /**
   * Get current tenant data.
   * @returns {object}
   */
  getTenantData() {
    return this.tenantData || {};
  }

  /**
   * Reset service's context about history & tenant.
   */
  resetAppContext() {
    this.tenantId = null;
    this.tenantData = null;
    document.getElementById('page_favicon').href = DefaultFavicon;
    document.title = 'Guest - Avendi';
  }

  /**
   * Get full path with params interpolated for navigation.
   *
   * @param {string} path - Route string (See constants/routes.js);
   * @param {object} params
   * @returns {string}
   */
  getFullPath(path, params = {}) {
    return generatePath(
      path,
      {
        tenantId: this.tenantId,
        ...params,
      }
    );
  }

  /**
   * Go to a specific route.
   *
   * @param {string} path - Route string (See constants/routes.js)
   * @param {object} [params = {}]
   * @param {object} [data = {}]
   */
  goTo(path, params = {}, data = {}) {
    if (
      path.includes(':tenantId')
      && !this.tenantId
      && !params.tenantId
    ) {
      throw new Error(`Can't route to ${path} because the current context doesn't have tenant information.`);
    }

    this.history.push(
      this.getFullPath(path, params),
      data
    );
  }

  /**
   * Go back from current location.
   */
  goBack() {
    this.history.goBack();
  }

  /**
   * Verify if tenant exist & if it does, get it's details.
   * @param {string|null} username
   * @returns
   */
  async verifyTenant(username = this.getTenantId()) {
    const overwrittenSettings = settingsPerClient[username] || { clientSettings: {} };
    if (overwrittenSettings.isAccessBlocked) {
      return { data: null };
    }

    try {
      const { data } = await axios.get(`${API.TENANT}/${username}`);
      if (!data) {
        Promise.reject(Error('No data'));
      }

      return { data: { ...data, ...overwrittenSettings } };
    } catch (error) {
      console.log(`Tenant ${username} doesn't exist.`);

      return { data: null };
    }
  }
}

const appService = new AppService();
export { appService }; // eslint-disable-line
