import MonaBannerLogo from "../assets/images/img/mona-banner-logo-dark.png";
import KGHGuestLogo from "../assets/images/img/kgh/kgh-logo-dark.png";
import HotelAmbassadorLogo from "../assets/images/ambassador.png";
import AvendiLogo from "../assets/images/avendi-dark.png";
import ShankerHotelLogo from "../assets/images/hotel-shanker.png";
import SouthernSunLogo from "../assets/images/southern-sun.png";

import KGHFavicon from "../assets/images/favicons/kgh.png";
import TsogoFavicon from "../assets/images/favicons/tsogo.svg";
import ShankerFavicon from "../assets/images/favicons/shanker.ico";
import WaterfrontFavicon from "../assets/images/favicons/waterfront.png";
import HimalayanFrontFavicon from "../assets/images/favicons/himalayan_front.png";
import { PinterToken } from "./enum";

const internalServiceNote =
  "Discover a variety of our in-house facilities to help you renew and recharge. Our services are offered from 9 am to 7 pm based on availability. For cancellation, please call +977 00000000";

const settingsPerClient = {
  "kgh.thamel": {
    clientSettings: {
      enableFaq: true,
      enableMuseumModule: true,
      enableInternalServices: true,
      colorLogo:
        "https://avendi-backend-media.s3.ap-southeast-1.amazonaws.com/profile/kgh-1631090752919.png",
      footerLogo:
        "https://avendi-backend-media.s3.ap-southeast-1.amazonaws.com/profile/kgh-1631090752919.png",
      guestAppHeaderRightLogo: MonaBannerLogo,
      faqAgentId: "0f98acf3-0eaa-4baa-9b37-835bed6185ad",
      internalServiceNote,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          breakfast: "from 07:00 am to 10:00 am",
          __default__: "from 07:00 am to 09:30 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
    guestAppLogoUrl: KGHGuestLogo,
    favicon: KGHFavicon,
  },
  "shanker.lazimpat": {
    clientSettings: {
      enableInternalServices: true,
      internalServiceNote,
      printerToken:PinterToken?.SHANKAR?.TOKEN,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          breakfast: "from 06:00 am to 10:30 am",
          "alcoholic beverages": "24 hours",
          "lavazza world": "24 hours",
          "hot beverages": "24 hours",
          "cold beverages": "24 hours",
          "late night menu": "from 10:00 pm to 06:00 am",
          __default__: "from 07:00 am to 09:00 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
      menuCategoryPriorities: {
        breakfast: 1,
        appetizer: 2,
        "soup & salad": 3,
        "main course": 4,
        chinese: 5,
        continental: 6,
        desserts: 7,
        "alcoholic beverages": 8,
        "lavazza world": 9,
        "hot beverages": 10,
        "cold beverages": 11,
      },
    },
    guestAppLogoUrl: ShankerHotelLogo,
    favicon: ShankerFavicon,
  },
  "southernsun.abudhabhi": {
    guestAppLogoUrl: SouthernSunLogo,
    isAccessBlocked: true,
    clientSettings: {
      internalServiceNote,
    },
    favicon: TsogoFavicon,
  },
  "waterfront.riverside": {
    clientSettings: {
      internalServiceNote,
      enableInternalServices: true,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          breakfast: "from 07:00 am to 10:00 am",
          __default__: "from 07:00 am to 09:45 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
      cleaningRequestNote:
        "Cleaning request is available from 06:00 am to 05:00 pm. ",
      laundryServiceNote:
        "Our laundry service is available from 06:00 am to 05:00 pm. ",
    },
    favicon: WaterfrontFavicon,
  },
  "himalayanfront.sarangkot": {
    clientSettings: {
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 08:00 am to 09:45 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency and inclusive of taxes.`;
      },
    },
    favicon: HimalayanFrontFavicon,
  },
  "parkvillage.budanilkantha": {
    clientSettings: {
      enableInternalServices: true,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          breakfast: "from 06:00 am to 10:00 am",
          __default__: "from 06:00 am to 09:30 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency and inclusive of taxes.`;
      },
    },
  },
  "ambassador.lazimpat": {
    guestAppLogoUrl: HotelAmbassadorLogo,
    clientSettings: {
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__:
            "In-room dining service is available from 12:00 noon to 10:00 pm. Last order is at 9:30pm. All prices are in local currency.",
          "late night menu":
            "Available 24hrs. All prices are in local currency.",
        };

        return (
          timings[menuCategory.toLowerCase().trim()] || timings.__default__
        );
      },
      laundryServiceNote:
        "If laundry is given before 09:00 am then the laundry will be back on the same day. For express laundry service (within 4hrs), the fee is double the normal rate.Laundry forms are kept in the drawer of the cupboard.",
      logoLargeAndCentered: true,
    },
  },
  "kasararesort.chitwan": {
    clientSettings: {
      disableInRoomDining: true,
    },
  },
  "nomad.lazimpat": {
    clientSettings: {
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 07:00 am to 10:00 pm",
          breakfast: "from 07:00 am to 10:30 am",
          lunch: "from 12:00 pm to 03:30 pm",
          dinner: "from 04:00 pm to 10:00 pm",
          pizza: "from 12:00 pm to 09:30 pm",
          "day special": "from 12:00 pm to 03:30 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
  },
  "buddhamaya.lumbini": {
    clientSettings: {
      internalServiceNote,
      enableInternalServices: true,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 07:00 am to 09:00 pm",
          breakfast: "from 07:00 am to 10:00 am",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
  },
  "mayamanor.naxal": {
    clientSettings: {
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 07:00 am to 09:30 pm",
          breakfast: "from 07:00 am to 10:00 am",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
  },
  "gokarna.kathmandu": {
    clientSettings: {
      internalServiceNote,
      enableInternalServices: true,
      printerToken: PinterToken?.GOKARNA?.TOKEN,

      menuCategoryPriorities: {
        "late night menu": 1,
      },
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 11:00 am to 10:30 pm",
          breakfast: "from 07:00 am to 10:00 am",
          "late night menu": "from 11:00 pm to 07:00 am",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
  },
  "clubhimalaya.nagarkot": {
    clientSettings: {
      internalServiceNote,
      enableInternalServices: true,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 06:30 am to 09:30 pm",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
  },
  "hoteltradition.thamel": {
    clientSettings: {
      internalServiceNote,
      enableInternalServices: true,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 07:00 am to 09:00 pm",
          breakfast: "from 07:00 am to 10:00 am",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
  },
  "avendi.production": {
    clientSettings: {
      internalServiceNote,
      enableMuseumModule: true,
      enableInternalServices: true,
      // printerToken: PinterToken?.AVENDI?.TOKEN,
      guestAppFoodMenuNote: (menuCategory) => {
        const timings = {
          __default__: "from 07:00 am to 09:00 pm",
          breakfast: "from 07:00 am to 10:00 am",
        };
        const timing =
          timings[menuCategory.toLowerCase().trim()] || timings.__default__;

        return `Served ${timing}. All prices are in local currency.`;
      },
    },
    guestAppLogoUrl: AvendiLogo,
 
  },

};
settingsPerClient["kathmanduguesthouse.thamel"] =
  settingsPerClient["kgh.thamel"];
settingsPerClient["southernsun.abudhabi"] =
  settingsPerClient["southernsun.abudhabhi"];
settingsPerClient["avendi.development"] =
  settingsPerClient["avendi.production"];

settingsPerClient["test.hotel"] = settingsPerClient["ambassador.lazimpat"];

export { settingsPerClient };
export default settingsPerClient;
