import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import React, { useEffect, useContext, useState } from "react";

import { GUEST_REQ_TYPE, GUEST_REQ_STATUS } from "../../constants";
import { StatusProgressBar } from "../../components/StatusProgressBar";
import { HotelServiceContext } from "../../contexts/HotelServiceContext";
import { appService } from "../../services/appService";
import { DynamicRoutes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { getValue } from "../../utils/object";

const GuestRequestTypeToLabel = Object.freeze({
  [GUEST_REQ_TYPE.FOOD_AND_BEV]: "In-Room Dining Request",
  [GUEST_REQ_TYPE.FRONT_OFFICE]: "Front Office Request",
  [GUEST_REQ_TYPE.HOUSE_KEEPING]: "Housekeeping Request",
  [GUEST_REQ_TYPE.INTERNAL_SERVICE]: "Experience",
});

const GuestRequestStatusToLabel = Object.freeze({
  [GUEST_REQ_STATUS.NOT_ASSIGNED]: "Order Received",
  [GUEST_REQ_STATUS.IN_PROGRESS]: "Processing",
  [GUEST_REQ_STATUS.COMPLETED]: "Completed",
  [GUEST_REQ_STATUS.BOOKED]: "Booked",
  [GUEST_REQ_STATUS.CANCELED]: "Canceled",
});

export const ActiveStatuses = Object.freeze([
  GUEST_REQ_STATUS.NOT_ASSIGNED,
  GUEST_REQ_STATUS.IN_PROGRESS,
  GUEST_REQ_STATUS.BOOKED,
]);
export const CompletedStatuses = Object.freeze([
  GUEST_REQ_STATUS.COMPLETED,
  GUEST_REQ_STATUS.CANCELED,
]);
const AllStatuses = Object.freeze([
  GUEST_REQ_STATUS.NOT_ASSIGNED,
  GUEST_REQ_STATUS.IN_PROGRESS,
  GUEST_REQ_STATUS.COMPLETED,
]);

export const CompletedRequest = ({ item, onPress, translation }) => {
  const time = new Date(item.updatedAt).toLocaleTimeString();

  return (
    <div
      role="listitem"
      className="guest-status-card d-flex align-items-center justify-content-between"
    >
      <div>
        <h4 className="mb-3">
          {getValue(translation, `${GuestRequestTypeToLabel[item.type]}`)}
          {typeof onPress === "function" && (
            <Link
              to="#view-details"
              onClick={(e) => {
                e.preventDefault();
                onPress();
              }}
              className="ml-2"
            >
              <small>({getValue(translation, 'viewDetails')})</small>
            </Link>
          )}
        </h4>
        <p className={GuestRequestStatusToLabel[item.status]?.toLowerCase()}>
          <FaCheckCircle className="mr-1 mb-1" size={16} />
          {getValue(translation, `${GuestRequestStatusToLabel[item.status]}`)}
        </p>
      </div>
      <p className="time">{time}</p>
    </div>
  );
};

export const ActiveRequest = ({ item, onPress, translation }) => {
  const time = new Date(item.updatedAt).toLocaleTimeString();

  return (
    <div role="listitem" className="guest-status-card">
      <div className="d-flex align-items-center justify-content-between">
        <div className="mb-2">
          <h4 className="mb-3">
            {getValue(translation, `${GuestRequestTypeToLabel[item.type]}`)}
            {typeof onPress === "function" && (
              <Link
                to="#view-details"
                onClick={(e) => {
                  e.preventDefault();
                  onPress();
                }}
                className="ml-2"
              >
                <small>({getValue(translation, 'viewDetails')})</small>
              </Link>
            )}
          </h4>
          <p className="color-black">
            {getValue(translation, `${GuestRequestStatusToLabel[item.status]}`)}
          </p>
        </div>
        <p className="time">{time}</p>
      </div>
      <StatusProgressBar
        statuses={AllStatuses}
        current={
          [GUEST_REQ_STATUS.IN_PROGRESS, GUEST_REQ_STATUS.BOOKED].includes(
            item.status
          )
            ? GUEST_REQ_STATUS.IN_PROGRESS
            : item.status
        }
      />
    </div>
  );
};

export default function MyStatus(props) {
  const { t } = useTranslation();
  const translation = t("status");
  const { requestStatus, myRequestStatus } = useContext(HotelServiceContext);

  const [activeRequests, setActiveRequests] = useState([]);
  const [completedRequests, setCompletedRequests] = useState([]);

  const groupActiveAndCompletedItems = (data = []) =>
    data.reduce(
      (result, curr) => {
        if (CompletedStatuses.includes(curr.status))
          result.completed.push(curr);
        else result.active.push(curr);

        return result;
      },
      { active: [], completed: [] }
    );

  useEffect(() => {
    myRequestStatus()
      .then((data) => {
        const { active, completed } = groupActiveAndCompletedItems(data);

        setActiveRequests(active);
        setCompletedRequests(completed);
      })
      .catch();
  }, []);

  return (
    <div className="col-12 pt-2">
      {!!activeRequests.length && (
        <>
          <div>
            <h1 className="h3 h3-small mb-4">{getValue(translation, 'Active')}</h1>
            <ul>
              {activeRequests.map((item, i) => (
                <ActiveRequest
                  item={item}
                  key={i}
                  onPress={() =>
                    appService.goTo(
                      DynamicRoutes.MyRequestDetails,
                      { id: item._id },
                      { item }
                    )
                  }
                  translation={translation}
                />
              ))}
            </ul>
          </div>
        </>
      )}
      {!!activeRequests.length && !!completedRequests.length && (
        <hr className="mt-5 mb-5" />
      )}
      {!!completedRequests.length && (
        <>
          <div>
            <h1 className="h3 h3-small mb-4">
              {getValue(translation, "Completed")}
            </h1>
            <ul>
              {completedRequests.map((item, i) => (
                <CompletedRequest
                  item={item}
                  key={i}
                  onPress={() =>
                    appService.goTo(
                      DynamicRoutes.MyRequestDetails,
                      { id: item._id },
                      { item }
                    )
                  }
                  translation={translation}
                />
              ))}
            </ul>
          </div>
        </>
      )}
      {!activeRequests.length && !completedRequests.length && (
        <p>{getValue(translation, "No request found")}</p>
      )}
    </div>
  );
}
