import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { getAccessToken, getUser } from '../../utils/sessionManager';
import { data } from './data';
import API_CONSTANT from '../../constants/api';
import { appService } from '../../services/appService';

const CURRENCY_NAME = 'AED';
const ACCESS_TOKEN = getAccessToken();
const APP_URL = process.env.REACT_APP_URL;

const currentUser = getUser();

export default function Details(props) {
  const { id } = props.match.params;
  const query = new URLSearchParams(props.location.search);
  const paynow = query.get('paynow');

  const [travelDetails, setTravelDetails] = useState(null);
  const [serviceDate, setServiceDate] = useState('');
  const [travellersCount, setTravellersCount] = useState('');
  const [loading, setLoading] = useState(false);

  const sendEmail = (payload) => {
    axios
      .post(`${API_CONSTANT.SEND_EMAIL}`, payload, {
        headers: {
          username: appService.getTenantId(),
          authorization: `Bearer ${ACCESS_TOKEN}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        alert('Email sent successfully!');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    setTimeout(() => {
      setServiceDate('');
      setTravellersCount('');
      setLoading(false);
      alert('Request sent successfully!');
    }, 5000);
  };

  const handleSendRequest = (e) => {
    e.preventDefault();
    if (!serviceDate || !travellersCount)
      return alert('Please select date and travellers');

    setLoading(true);
    const payload = {
      action: 'travel_desk',
      send_to: 'thebinod7@gmail.com',
      subject: 'Travel Desk Request',
      data: {
        guestName: `${currentUser.firstName} ${currentUser.lastName}`,
        redirectUrl: APP_URL,
        serviceDate,
        travellersCount,
        itemId: id,
      },
    };

    return sendEmail(payload);
  };

  const fetchDetails = () => {
    const details = data.find((item) => item.id === parseInt(id));
    if (details) setTravelDetails(details);
  };

  const handleDateInputChange = (e) => {
    setServiceDate(e.target.value);
  };

  const handleInputChange = (e) => {
    setTravellersCount(parseInt(e.target.value));
  };

  useEffect(fetchDetails, [id]);

  return (
    <>
      <section className="single_page_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
              <div className="single_fpage_area">
                <div className="single_fbanner">
                  <img
                    src={
                      travelDetails && travelDetails.image
                        ? travelDetails.image
                        : ''
                    }
                    alt="food"
                  />
                </div>
                <div className="single_page_box">
                  <div className="items_gap">
                    <div className="box_shadow single_page">
                      <div className="single_page_content">
                        <h1>{travelDetails && travelDetails.name}</h1>
                        <h1>
                          {CURRENCY_NAME} &nbsp;
                          {(travelDetails && travelDetails.price) || 0}
                        </h1>
                        <p>
                          {travelDetails && travelDetails.description
                            ? travelDetails.description
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="items_gap">
                    <div className="box_shadow single_page">
                      <h4>Select date and travellers</h4> <br />
                      <div className="form-group">
                        <input
                          style={{ width: '100%', height: '2.5rem' }}
                          onChange={handleDateInputChange}
                          name="serviceDate"
                          type="date"
                          placeholder="Choose date"
                          value={serviceDate}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          value={travellersCount}
                          onChange={handleInputChange}
                          type="number"
                          className="form-control"
                          placeholder="Enter number of travellers"
                        />
                      </div>
                    </div>
                  </div>

                  {paynow && paynow === 'yes' ? (
                    <div className="single_pbutton">
                      <Link to="#paynow">Pay Now</Link>
                    </div>
                  ) : (
                    <div className="single_pbutton">
                      {loading ? (
                        'Sending request...'
                      ) : (
                        <Link
                          to="#send_request"
                          onClick={(e) => handleSendRequest(e)}
                        >
                          Request to Book
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
