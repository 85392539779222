import { useCallback, useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchDineAndDrinks } from "../../services/profileServices";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import NotFound from "../../assets/images/img/Ambassador.png";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";
import { getSelectedLanguage } from "../../utils/localStorage";
import { translateTextToTargetLanguage } from "../../services/googleTranslate";

const ViewAllDineAndDrinks = () => {
  const { dineAndDrinks, setDineAndDrinks } = useContext(ProfileContext);
  const { t } = useTranslation();
  const translation = t("profile");

  const selectedLang = getSelectedLanguage();

  const [isLoading, setIsLoading] = useState(false);
  const [textLength, setTextLength] = useState([]);

  const [translatedData, setTranslatedData] = useState(dineAndDrinks);

  const handleToggleTextLength = (index) => {
    const newLengths = [...textLength];
    newLengths[index] =
      newLengths[index] === 500
        ? dineAndDrinks[index]?.description?.length
        : 500;
    setTextLength(newLengths);
  };

  const fetchData = useCallback(async () => {
    try {
      if (!dineAndDrinks) {
        setIsLoading(true);
        const data = await fetchDineAndDrinks();
        setDineAndDrinks(data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  }, []);

  const translateTextBatch = async (texts, targetLang) => {
    return translateTextToTargetLanguage({ text: texts, targetLang });
  };

  const translateDineAndDrink = useCallback(async () => {
    if (!dineAndDrinks) return [];

    if (selectedLang === "en") {
      return dineAndDrinks.map((data) => ({
        ...data,
        tags: data.tags.join(" ** "),
      }));
    }

    const dineAndDrinkPromises = dineAndDrinks?.map(async (data) => {
      const description = getValue(data, "description")?.replace(/&/g, "and");
      const tags = getValue(data, "tags", [])
        ?.join(" ** ")
        ?.replace(/&/g, "and");

      const [translatedDescription, translatedTags] = await Promise.all([
        translateTextBatch(description, selectedLang),
        translateTextBatch(tags, selectedLang),
      ]);

      return {
        ...data,
        description: translatedDescription,
        tags: translatedTags,
      };
    });

    const tDineAndDrink = await Promise.all(dineAndDrinkPromises);
    return tDineAndDrink;
  }, [selectedLang, dineAndDrinks]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchTranslatedCategoryList = async () => {
      const tDineAndDrink = await translateDineAndDrink();
      setTranslatedData(tDineAndDrink);
    };

    fetchTranslatedCategoryList();
  }, [translateDineAndDrink]);

  return (
    <section
      style={{
        maxWidth: "400px",
        margin: "auto",
        boxShadow: "initial",
        WebkitBoxShadow: "initial",
      }}
      className="sha   "
    >
      <h1
        style={{ fontSize: "25px" }}
        className="my-1 font-weight-bold mx-3 my-3"
      >
        {getValue(translation, "dineAndDrink")}
      </h1>
      {isLoading ? (
        <div className=" d-flex justify-content-center mt-5">
          <Spinner size="10" animation="border" variant="avendi-gray" />
        </div>
      ) : (
        <article className=" mt-3 px-3">
          {translatedData?.map((item, index) => (
            <div key={index} className="mb-3 all_properties cursor_pointer">
              <img
                src={item?.diningImage ?? NotFound}
                alt={item?.name}
                className="img-fluid"
              />
              <h4 className="my-2 mx-2">{item?.name}</h4>
              <p className="mx-2 mb-2">
                {item?.description?.slice(0, textLength[index] || 500)}

                {item?.description?.length > 500 && (
                  <>
                    {textLength[index] !== item?.description?.length ? (
                      <>
                        <span
                          style={{ fontWeight: 600 }}
                          className="ml-1"
                          onClick={() => handleToggleTextLength(index)}
                        >
                          ...{getValue(translation, "readMore")}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{ fontWeight: 600 }}
                          className=" ml-1"
                          onClick={() => handleToggleTextLength(index)}
                        >
                          {" "}
                          {getValue(translation, "showLess")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </p>
              <p className="mb-2 mx-2 d-flex align-items-center">
                {/* <IoLocationOutline /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M3.49973 11.6667H5.24973M5.24973 11.6667H6.99973M5.24973 11.6667V8.75M5.24973 8.75C5.24973 8.75 3.20806 7.658 2.62473 6.41667C1.99473 5.07675 2.62473 2.625 2.62473 2.625H7.87473C7.87473 2.625 8.50473 5.07675 7.87473 6.41667C7.29139 7.658 5.24973 8.75 5.24973 8.75ZM9.91639 11.6667V7C9.91639 7 11.3747 6.41667 11.3747 5.25V2.625M9.91639 4.95833V2.625"
                    stroke="#696969"
                    strokeWidth="1.225"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ml-1">{item.tags}</span>
              </p>
            </div>
          ))}
        </article>
      )}
    </section>
  );
};

export default ViewAllDineAndDrinks;
