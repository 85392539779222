const updateRecentTenants = (tenantData) => {
  const recentTenants = localStorage.getItem('recent_tenants');
  const recentTenantsData = JSON.parse(recentTenants) || [];
  const tenantDataAlreadyExists = !!recentTenantsData.find((data) => data._id === tenantData._id);

  if (!tenantDataAlreadyExists) {
    recentTenantsData.push(tenantData);
    localStorage.setItem('recent_tenants', JSON.stringify(recentTenantsData));
  }
};

const getRecentTenants = () => {
  const recentTenantsData = localStorage.getItem('recent_tenants');

  return JSON.parse(recentTenantsData) || [];
};

export { updateRecentTenants, getRecentTenants };
