import React from "react";
import FoodHeader from "../partials/header/foodHeader";

export default function AltLayout(props) {
  return (
    <>
      <FoodHeader />
      {props.children}
    </>
  );
}
