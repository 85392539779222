import { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import { DynamicRoutes } from "../../constants/routes";
import { fetchDineAndDrinks } from "../../services/profileServices";
import { ProfileContext } from "../../contexts/ProfileContext";
import NotFound from "../../assets/images/img/no-image-available.png";
import { getValue } from "../../utils/object";

const DineAndDrink = ({translation}) => {
  const { dineAndDrinks, setDineAndDrinks } = useContext(ProfileContext);

  const fetchData = useCallback(async () => {
    try {
      if (!dineAndDrinks) {
        const data = await fetchDineAndDrinks();
        setDineAndDrinks(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="profile_article">
      <div className="d-flex justify-content-between align-items-center">
        <h2>{getValue(translation, 'dineAndDrink')}</h2>
        {dineAndDrinks?.length > 1 && (
          <div className="d-flex align-items-center">
            <Link to={DynamicRoutes.ViewallDineAndDrinks}>
              <h5>{getValue(translation, 'viewAll')}</h5>
            </Link>
            <RiArrowRightLine
              style={{ fontSize: "20px" }}
              className="text-dark cursor_pointer"
            />
          </div>
        )}
      </div>
      <article className=" mt-3 d-flex ">
        {dineAndDrinks?.map((item) => (
          <Link
            to={DynamicRoutes.ViewallDineAndDrinks}
            key={item?._id}
            className="properties_card"
          >
            <img src={item?.diningImage ?? NotFound} alt="" className="" />
            <h4 className="my-2 mx-2"> {item?.name}</h4>
            <p className="mb-2 mx-2 d-flex align-items-center">
              {/* <IoLocationOutline /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M3.49973 11.6667H5.24973M5.24973 11.6667H6.99973M5.24973 11.6667V8.75M5.24973 8.75C5.24973 8.75 3.20806 7.658 2.62473 6.41667C1.99473 5.07675 2.62473 2.625 2.62473 2.625H7.87473C7.87473 2.625 8.50473 5.07675 7.87473 6.41667C7.29139 7.658 5.24973 8.75 5.24973 8.75ZM9.91639 11.6667V7C9.91639 7 11.3747 6.41667 11.3747 5.25V2.625M9.91639 4.95833V2.625"
                  stroke="#696969"
                  strokeWidth="1.225"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-1">
                {item?.tags[0]} • {item?.tags[1]}
              </span>
            </p>
          </Link>
        ))}
      </article>
    </div>
  );
};

DineAndDrink.propTypes = {
  translation: PropTypes.object.isRequired,
};

export default DineAndDrink;
