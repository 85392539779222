import USAIcon from "../../assets/images/img/countries/usa.png";
import JapanIcon from "../../assets/images/img/countries/japan.png";
import IndiaIcon from "../../assets/images/img/countries/india.png";
import ChinaIcon from "../../assets/images/img/countries/china.png";
import ArabIcon from "../../assets/images/img/countries/arab.png";
import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../hooks/clickoutside.hook";
import { useTranslation } from "react-i18next";
import { PiTranslateBold, PiLineVerticalLight } from "react-icons/pi";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoChevronDownOutline } from "react-icons/io5";

import { getLanguageTranslationStatus } from "../../utils/localStorage";

const LANGUAGE_LIST = [
  { name: "English", icon: USAIcon, code: "en", shortCode: "English" },
  { name: "हिन्दी", icon: IndiaIcon, code: "hi", shortCode: "Hin" },
  { name: "中文", icon: ChinaIcon, code: "cn", shortCode: "Chi" },
  { name: "日本語", icon: JapanIcon, code: "ja", shortCode: "Jpn" },
  { name: "العربية", icon: ArabIcon, code: "ar", shortCode: "Ara" },
];

const LanguageSelect = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return <></>;
  }

  const languageTranslationEnabled = getLanguageTranslationStatus();
  if (!languageTranslationEnabled) {
    return <></>;
  }

  const languageRef = useRef();
  const { i18n } = useTranslation();
  const [showLanguageList, setShowLanguageList] = useState(false);
  const localStorageLang = localStorage.getItem("avendi-lang");
  const defaultSelectedLang =
    localStorageLang && localStorageLang !== "null"
      ? JSON.parse(localStorageLang)
      : LANGUAGE_LIST[0];

  const [selectedLanguage, setSelectedLanguage] = useState(defaultSelectedLang);

  useClickOutside(languageRef, showLanguageList, setShowLanguageList);

  const handleLanguageChange = ({ lang }) => {
    i18n.changeLanguage(lang?.code);
    setSelectedLanguage(lang);
    setShowLanguageList(false);
    localStorage.setItem("avendi-lang", JSON.stringify(lang));
  };

  useEffect(() => {
    i18n.changeLanguage(defaultSelectedLang?.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageLang]);

  return (
    <div style={{ position: "relative", right: 5 }} ref={languageRef}>
      <button
        className="selected-language"
        onClick={() => {
          setShowLanguageList(!showLanguageList);
        }}
      >
        {/* <PiTranslateBold className="translate-icon" /> */}
        {/* <PiLineVerticalLight className="vertical-icon" /> */}
        <span className="selected-language-text">
          {selectedLanguage?.shortCode}
        </span>

        {/* <IoMdArrowDropdown className="dropdown-icon" /> */}
        <IoChevronDownOutline />
      </button>

      {showLanguageList && (
        <ul className="lang-parent">
          {LANGUAGE_LIST.map((lang) => (
            <li
              className="lang-list"
              key={lang.name}
              onClick={() => handleLanguageChange({ lang: lang })}
              style={{
                backgroundColor:
                  selectedLanguage?.code === lang.code ? "#EAE8E8" : "",
              }}
            >
              <span>{lang.name}</span>
              <span>-</span>
              <span>{lang.shortCode}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelect;
