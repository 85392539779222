import React from "react";
import { BsChevronDown } from "react-icons/bs";

export default function LoadMore({ handleLoadMoreClick }) {
  return (
    <>
      <div className="app_load_more">
        <span
          className="app_show_more_action show_more_new"
          onClick={handleLoadMoreClick}
        >
          Show More <BsChevronDown style={{ verticalAlign: "middle" }} />
        </span>
      </div>
    </>
  );
}
