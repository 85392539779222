import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

import { HotelServiceContext } from "../../contexts/HotelServiceContext";
import { GUEST_REQ_TYPE, SERVICE_TYPE } from "../../constants";

import { DynamicRoutes } from "../../constants/routes";
import { getValue } from "../../utils/object";

export default function Index() {
  const { submitGuestRequest } = useContext(HotelServiceContext);
  const { addToast } = useToasts();

  const { t } = useTranslation();
  const translation = t("services");

  const [requestItem, setRequestItem] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleRequestInputChange = (e) => setRequestItem(e.target.value);

  const sendGuestRequest = (payload) => {
    setIsSubmitting(true);
    submitGuestRequest(payload)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => {
        addToast("Internal server error!", {
          autoDismiss: true,
          appearance: "error",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      details: requestItem,
    };
    payload.type = GUEST_REQ_TYPE.HOUSE_KEEPING;
    payload.requestType = SERVICE_TYPE.REQUEST_ITEM;
    return sendGuestRequest(payload);
  };

  return (
    <>
      <section className="message_area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="message_box">
                <h1>{getValue(translation, "requestItem.title")}</h1>
                {isSubmitted ? (
                  <div className="report_message_box ha_button">
                    <p>{getValue(translation, "requestItem.successMsg")}</p>

                    <Link to={DynamicRoutes.Services}>
                      {getValue(translation, "requestItem.goBack")}
                    </Link>
                  </div>
                ) : (
                  <>
                    <p>{getValue(translation, "requestItem.description")}</p>
                    <form onSubmit={handleFormSubmit}>
                      <div className="message_input ha_login_input">
                        <textarea
                          name="requestItem"
                          placeholder={getValue(
                            translation,
                            "requestItem.placeholder"
                          )}
                          rows={10}
                          value={requestItem}
                          onChange={handleRequestInputChange}
                          required
                        />
                      </div>
                      <div className="ha_button mssg_button">
                        <input
                          type="submit"
                          value={getValue(
                            translation,
                            "requestItem.requestBtn"
                          )}
                          readOnly
                          disabled={isSubmitting || !requestItem.trim().length}
                        />
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
